import { useState } from "react";
import { Toaster } from "react-hot-toast";

import UpdateCommissionPercentageModal from "../components/commission-percentage-modal";
import PromoteAssociateToManagerModal from "../components/promote-associate-to-manager-modal";

const LatestCommissionDashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, setModal] = useState({
    isOpen: false,
  });
  const dashboardLinks = [
    {
      label: "Manage Commission Percentage",
      link: "/manage/commission/dashboard",
      isPopup: true,
    },
    {
      label: "Manage Associates",
      link: "/manage/commission/associates",
    },
    // {
    //   label: "Manage Heads",
    //   link: "/manage/commission/heads",
    // },
    {
      label: "Manage Managers",
      link: "/manage/commission/managers",
    },
    // {
    //   label: "Manage Provincial Director",
    //   link: "/manage/commission/provincial-directors",
    // },
    {
      label: "Manage Directors",
      link: "/manage/commission/directors",
    },
    // {
    //   label: "Manage Heads",
    //   link: "/manage/commission/heads",
    // },
    // {
    //   label: "View All Users",
    //   link: "/manage/commission",
    // },
    // {
    //   label: "Promote Head To Manager",
    //   link: "/manage/commission/promote/head- to-manager",
    // },
    {
      label: "Manage Reimbursements",
      link: "/manage/commission/reimbursements",
    },
    {
      label: "Transfer Users",
      link: "/manage/commission/transfer-user",
    },
    {
      label: "Manage Coupon/Code",
      link: "/manage/commission/coupons",
    },
    {
      label: "Manage Targets",
      link: "/manage/targets",
    },
    {
      label: "Promote Associate To Manager",
      link: "/manage/commission/dashboard",
      isPopup: true,
    },
    {
      label: "Pending Sales",
      link: "/manage/commission/sales",
      // isPopup: true,
    },
  ];

  const handleLink = (route: {
    label: string;
    link: string;
    isPopup?: boolean;
  }) => {
    if (route?.isPopup) {
      if (route?.label === "Manage Commission Percentage") {
        setIsModalOpen(true);
      }
      if (route?.label === "Promote Associate To Manager") {
        setModal({
          isOpen: true,
        });
      }
    } else {
      window.open(route?.link, "_blank");
    }
  };

  return (
    <>
      <Toaster />
      {/* =========================== */}
      {/* =========================== */}
      {/*  Update Commission Percentage Modal */}
      <UpdateCommissionPercentageModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      {/* =========================== */}
      {/* =========================== */}

      {/*  Update Commission Percentage Modal */}
      <PromoteAssociateToManagerModal modal={modal} setModal={setModal} />
      {/* =========================== */}
      {/* =========================== */}

      <div className="w-full pb-20 px-5">
        <div className="flex items-center justify-between pb-1 mt-5">
          <h2 className="text-2xl font-bold text-gray-500 mb-5">
            Commission Mega Dashboard
          </h2>
        </div>

        <section className="my-5 antialiased flex flex-wrap items-center gap-5 ">
          {dashboardLinks &&
            dashboardLinks?.map((route) => {
              return (
                <div className="">
                  <button
                    className="text-white bg-gradient-to-r from-primary-400 via-primary-500 to-primary-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-primary-300 shadow-lg shadow-primary-500/50 font-medium rounded-lg  p-6 text-center text-lg transition-all ease-in-out"
                    onClick={() => {
                      handleLink(route);
                    }}
                  >
                    {route?.label}
                  </button>
                </div>
              );
            })}
        </section>
      </div>
    </>
  );
};

export default LatestCommissionDashboard;
