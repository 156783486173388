import { PackagesAPI } from '(apis)/(shared-apis)/packages-apis';
import { apiRequest } from '(apis)/api-interface/api-handler';
import { DeleteOutline, Edit } from '@mui/icons-material';
import { Select } from 'antd';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import ReactQuill from 'react-quill';
import { ButtonFill, ButtonOutlined } from 'shared-components/ui/CustomButtons';
import CustomDropDown from 'shared-components/ui/CustomDropDown';
import { Input, Label } from 'shared-components/ui/CustomForm';

export default function AddPricingModal(props) {

    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState<'add' | 'edit'>('add');

    const Submit = async () => {

        if (!formData.SKUName || formData.SKUName === "") {
            toast.error("SKU Name is required");
            return;
        }
        if (formData.amountOfCourses < 0) {
            toast.error("Amount of Courses should be positive");
            return;
        }
        let invalidDiscount = false;
        let invalidPrice = false;
        let invalidNumberOfUsers = false;
        formData.toggleValuesAndPricings.map((toggle) => {
            if (toggle.price < 0) {
                invalidPrice = true;
            }
            if (toggle.discount < 0 || toggle.discount > 10) {
                invalidDiscount = true;
            }
        })
        formData.groupBundle.map((group) => {
            if (group.price < 0) {
                invalidPrice = true;
            }
            if (group.discountPerUser < 0 || group.discountPerUser > 10) {
                invalidDiscount = true;
            }
            if (group.numberOfUsers < 0) {
                invalidNumberOfUsers = true;
            }
        })

        if (invalidPrice) {
            toast.error("Prices should be positive");
            return;
        }
        if (invalidDiscount) {
            toast.error("Discounts should be between 0 and 10 only");
            return;
        }
        if (invalidNumberOfUsers) {
            toast.error("Number of Users should be positive");
            return;
        }

        setLoading(true);
        try {
            if (props?.mode === 'edit') {
                const response = await PackagesAPI("updateSKU", [props?.packageData?._id], {
                    ...formData,
                    _id: props.selectedSKU._id
                });
                if (response.data.success) {
                    toast.success("Pricing Plan Updated Successfully");
                    props?.setModal(false);
                    props?.fetchPackage();
                }
                else {
                    toast.error("Failed to update pricing plan");
                }
                setLoading(false);
                return;
            }
            else {
                const response = await PackagesAPI("addSKUToPackage", [props?.packageData?._id], formData);
                if (response.data.success) {
                    toast.success("Pricing Plan Added Successfully");
                    props?.setModal(false);
                    props?.fetchPackage();
                }
                else {
                    toast.error("Failed to add pricing plan");
                }
                setLoading(false);
                return;
            }
        }
        catch (error) {
            console.log("Error in adding pricing plan: ", error);
            toast.error("Failed to add pricing plan");
        }
        finally {
            setLoading(false);
        }
    }

    const [formData, setFormData] = useState({
        SKUName: "",
        Tag: "",
        PreMedAccess: [],
        amountOfCourses: 0,
        Description: "",
        tagDetails: [],
        toggleValuesAndPricings: [],
        groupBundle: [],        
        position: 1000,
    });

    useEffect(() => {
        if (props?.packageData && props?.mode === 'add') {
            const newToggleValuesAndPricings = props.packageData.togglesAndPricings.map((toggle) => ({
                _id: toggle?._id,
                price: 0,
                discount: 0,
                active: true
            }));

            const groupBundlePricing = props.packageData.togglesAndPricings.map((toggle) => ({
                _id: toggle?._id,
                price: 0,
                numberOfUsers: 0,
                discountPerUser: 0,
                active: true
            }));

            setFormData({
                ...formData,
                toggleValuesAndPricings: newToggleValuesAndPricings,
                groupBundle: groupBundlePricing
            });
        }
        else if (props?.packageData && props?.mode === 'edit') {
            const newToggleValuesAndPricings = props.packageData.togglesAndPricings.map((toggle) => {
                const FindSku = toggle?.skuPricings.find((sku: any) => sku.skuId === props.selectedSKU._id);
                return {
                    _id: toggle?._id,
                    price: FindSku?.price || 0,
                    discount: FindSku?.discount || 0,
                    active: FindSku?.active || false
                };
            });

            const groupBundlePricing = props.packageData.togglesAndPricings.map((toggle) => {
                const FindSku = toggle?.groupBundlePricings.find((sku: any) => sku.skuId === props.selectedSKU._id);
                return {
                    _id: toggle?._id,
                    price: FindSku?.price || 0,
                    numberOfUsers: FindSku?.numberOfUsers || 0,
                    discountPerUser: FindSku?.discountPerUser || 0,
                    active: FindSku?.active || false
                };
            })
            setFormData({
                ...formData,
                toggleValuesAndPricings: newToggleValuesAndPricings,
                groupBundle: groupBundlePricing
            });
        }
    }, [props?.packageData, props?.mode, props.selectedSKU]);

    useEffect(() => {
        if (props?.selectedSKU && props?.mode === 'edit') {
            setFormData((prevData) => ({
                ...prevData,
                SKUName: props.selectedSKU.SKUName,
                Tag: props.selectedSKU.Tag,
                PreMedAccess: props.selectedSKU.PreMedAccess,
                amountOfCourses: props.selectedSKU.amountOfCourses,
                Description: props.selectedSKU.Description,
                tagDetails: props.selectedSKU.tagDetails,
                position: props.selectedSKU.position,
            }));
        }
        else {
            setFormData((prevData) => ({
                ...prevData,
                SKUName: "",
                Tag: "",
                PreMedAccess: [],
                amountOfCourses: 0,
                Description: "",
                tagDetails: [],
                position: 1000,
            }));
        }
    }, [props.selectedSKU, props.mode]);


    const handleInputChange = (name: string, value: string) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const [accessTags, setAccessTags] = useState([])
    const GetDiscountTags = async () => {
        try {
            const response = await apiRequest("getDistintTags");
            if (response?.data?.tags) {
                setAccessTags(response.data.tags);
            }
        } catch (error) {
            console.log("Error in fetching discount tags: ", error);
        }
    }

    useEffect(() => {
        GetDiscountTags();
    }, [])

    const [tagToBeAdded, setTagToBeAdded] = useState({
        index: 0,
        description: "",
        logo: "",
        isActive: true,
        position: 1000,
    });

    const AddTagDetails = () => {
        if (!tagToBeAdded.description || tagToBeAdded.description.trim() === "") {
            toast.error("Description is required");
            return;
        }
        if (!tagToBeAdded.logo || tagToBeAdded.logo.trim() === "") {
            toast.error("Logo is required");
            return;
        }

        const newTag = {
            ...tagToBeAdded,
            id: Date.now(),
            description: tagToBeAdded.description.trim(),
            position: tagToBeAdded.position || 1000
        };

        setFormData(prevFormData => ({
            ...prevFormData,
            tagDetails: [...prevFormData.tagDetails, newTag]
        }));

        setTagToBeAdded({
            index: 0,
            description: "",
            logo: "",
            isActive: true,
            position: 1000,
        });
    }

    const EditTagDetails = () => {
        if (!tagToBeAdded.description || tagToBeAdded.description.trim() === "") {
            toast.error("Description is required");
            return;
        }

        if (tagToBeAdded.logo === "") {
            toast.error("Logo is required");
            return;
        }

        setFormData(prevFormData => ({
            ...prevFormData,
            tagDetails: prevFormData.tagDetails.map((tag, index) => 
                index === tagToBeAdded.index 
                    ? {
                        ...tag,
                        description: tagToBeAdded.description.trim(),
                        logo: tagToBeAdded.logo || tag.logo,
                        position: tagToBeAdded.position || tag.position,
                        isActive: tagToBeAdded.isActive ?? tag.isActive
                    }
                    : tag
            )
        }));

        setTagToBeAdded({
            index: 0,
            description: "",
            logo: "",
            isActive: true,
            position: 1000,
        });
        setMode("add");
    }

    const DeleteTagDetail = (indexToDelete: number) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            tagDetails: prevFormData.tagDetails.filter((_, index) => index !== indexToDelete)
        }));
    }

    const StartEditTagDetail = (tag: any, index: number) => {
        setTagToBeAdded({
            index: index,
            description: tag?.description || "",
            logo: tag?.logo || "",
            isActive: tag?.isActive ?? true,
            position: tag?.position || 1000
        });
        setMode('edit');
    }

    const HandleToggleValuesAndPricings = (index: number, value: string, key: string) => {
        const updatedTogglesAndPricings = formData.toggleValuesAndPricings.map((toggle, i) => {
            if (i === index) {
                return {
                    ...toggle,
                    [key]: value
                }
            }
            return toggle
        })
        setFormData({
            ...formData,
            toggleValuesAndPricings: updatedTogglesAndPricings
        })
    }

    const HandleGroupBundle = (index: number, value: string, key: string) => {
        const updatedGroupBundle = formData.groupBundle.map((group, i) => {
            if (i === index) {
                return {
                    ...group,
                    [key]: value
                }
            }
            return group
        }
        )
        setFormData({
            ...formData,
            groupBundle: updatedGroupBundle
        })
    }

    return (
        <div
            id="createProductModal"
            tabIndex={-1}
            aria-hidden="true"
            className={`${props?.active ? "" : "hidden"
                } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen backdrop-blur-sm !space-x-0`}
        >
            <div className="relative w-full max-w-7xl max-h-full p-4">
                <div className="relative p-4 bg-white rounded-lg shadow ">
                    <div className="flex items-center justify-between pb-4 mb-4 border-b rounded-t sm:mb-5">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            {props?.mode === 'add' ? 'Add' : 'Edit'}{" "} Pricing Plan
                        </h3>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover.bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                            data-modal-target="createProductModal"
                            data-modal-toggle="createProductModal"
                            onClick={() => {
                                props?.setModal(false);
                            }}
                        >
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className='grid grid-cols-1 md:grid-cols-3 gap-4 items-start'>
                        <div>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>

                                <div className="space-y-2">
                                    <div className="space-y-2">
                                        <Label>SKU Name</Label>
                                        <Input
                                            type="text"
                                            placeholder="Enter SKU Name"
                                            value={formData.SKUName}
                                            name="SKUName"
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <div className="space-y-2">
                                        <CustomDropDown
                                            width='w-full'
                                            label='Ribbon Tag'
                                            options={RibbonTags}
                                            onSelect={(value) => {
                                                setFormData({
                                                    ...formData,
                                                    Tag: value
                                                })
                                            }}
                                            value={formData.Tag}
                                        />
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <div className="space-y-2">
                                        <Label>Amount of Courses</Label>
                                        <Input
                                            type="number"
                                            placeholder="Enter Amount of Courses"
                                            value={formData.amountOfCourses}
                                            name="amountOfCourses"
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <div className="space-y-2">
                                        <Label>Description</Label>
                                        <Input
                                            type="text"
                                            placeholder="Enter Description"
                                            value={formData.Description}
                                            name="Description"
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {props?.packageData?.togglesAndPricings?.map((toggle, index) => (
                                <div className='flex flex-col gap-2 mt-2'>
                                    <div className='text-md font-semibold text-gray-900'>
                                        {toggle?.toggleValue}
                                    </div>
                                    <div className='grid grid-cols-1 md:grid-cols-3 gap-2 items-start'>
                                        <div className="space-y-1">
                                            <Label>Price</Label>
                                            <input
                                                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-white disabled:text-gray-400 disabled:font-light"
                                                type="number"
                                                placeholder="Enter Price"
                                                value={formData.toggleValuesAndPricings[index]?.price}
                                                name='price'
                                                onChange={(e) => {
                                                    HandleToggleValuesAndPricings(index, e.target.value, 'price')
                                                }}
                                            />
                                        </div>
                                        <div className="space-y-1">
                                            <Label>Discount</Label>
                                            <input
                                                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-white disabled:text-gray-400 disabled:font-light"
                                                type="number"
                                                placeholder="Enter Discount"
                                                value={formData.toggleValuesAndPricings[index]?.discount}
                                                name='discount'
                                                onChange={(e) => {
                                                    HandleToggleValuesAndPricings(index, e.target.value, 'discount')
                                                }}
                                            />
                                        </div>
                                        <div className="space-y-1">
                                            <Label>Active</Label>
                                            <Select
                                                style={{ width: "100%", height: '45px' }}
                                                placeholder="Select Active"
                                                value={formData.toggleValuesAndPricings[index]?.active}
                                                onChange={(value) => {
                                                    const updatedTogglesAndPricings = formData.toggleValuesAndPricings.map((toggle, i) => {
                                                        if (i === index) {
                                                            return {
                                                                ...toggle,
                                                                active: value
                                                            }
                                                        }
                                                        return toggle
                                                    })
                                                    setFormData({
                                                        ...formData,
                                                        toggleValuesAndPricings: updatedTogglesAndPricings
                                                    })
                                                }}
                                            >
                                                <Select.Option key='true' value={true}>
                                                    True
                                                </Select.Option>
                                                <Select.Option key='false' value={false}>
                                                    False
                                                </Select.Option>
                                            </Select>
                                        </div>



                                    </div>
                                </div>
                            ))}

                            <div className="space-y-2 mt-4">
                                <div className="space-y-2">
                                    <Label>Pre-Med Access</Label>
                                    <Select
                                        mode="multiple"
                                        style={{ width: "100%" }}
                                        placeholder="Select Pre-Med Access"
                                        value={formData.PreMedAccess}
                                        onChange={(value) => {
                                            setFormData({
                                                ...formData,
                                                PreMedAccess: value
                                            })
                                        }}
                                    >
                                        {accessTags.map((tag) => (
                                            <Select.Option key={tag} value={tag}>
                                                {tag}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="space-y-2">
                            <div className="space-y-2">
                                <Label>Tag Details</Label>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div className="space-y-2">
                                        <CustomDropDown
                                            width='w-full'
                                            label='Logo'
                                            options={Logos}
                                            onSelect={(value) => {
                                                setTagToBeAdded({
                                                    ...tagToBeAdded,
                                                    logo: value
                                                })
                                            }}
                                            value={tagToBeAdded.logo}
                                        />
                                    </div>
                                    <div className="space-y-2">
                                        <Label>Position</Label>
                                        <input
                                            type="number"
                                            className='w-full rounded border-[1.5px] border-stroke bg-transparent py-2 px-5 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-white disabled:text-gray-400 disabled:font-light'
                                            placeholder="Enter Position"
                                            name="position"
                                            onChange={(e: any) => {
                                                setTagToBeAdded({
                                                    ...tagToBeAdded,
                                                    position: e.target.value
                                                })
                                            }}
                                            value={tagToBeAdded.position}
                                        />
                                    </div>
                                </div>
                                <ReactQuill
                                    id={`description`}
                                    placeholder={`Enter Description`}
                                    value={tagToBeAdded.description}
                                    onChange={(content) =>
                                        setTagToBeAdded({
                                            ...tagToBeAdded,
                                            description: content
                                        })
                                    }
                                    className="border border-gray-300 rounded"
                                />
                                <div className="flex items-center justify-end gap-2">
                                    <ButtonFill handleClick={mode === 'add' ? AddTagDetails : EditTagDetails}>
                                        {mode === 'add' ? 'Add' : 'Edit'}
                                    </ButtonFill>
                                </div>
                                <div className=" bg-white shadow-md sm:rounded-lg mt-4">
                                    <div className="overflow-x-auto">
                                        <table className="w-full mb-20 text-sm text-left text-gray-500">
                                            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                                <tr>
                                                    <th scope="col" className="px-4 py-4">
                                                        Logo
                                                    </th>
                                                    <th scope="col" className="px-4 py-4 text-center">
                                                        Description
                                                    </th>
                                                    <th scope="col" className="px-4 py-4 text-center">
                                                        Position
                                                    </th>
                                                    <th scope="col" className="px-4 py-3 text-center">
                                                        Actions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {formData.tagDetails.map((tag, index) => (
                                                    <tr className="border-b" key={index}>
                                                        <td className="px-4 py-3">{tag?.logo}</td>
                                                        <td className="px-4 py-3">
                                                            {typeof tag?.description === 'string' 
                                                                ? tag.description.substring(0, 10) + '...' 
                                                                : '...'}
                                                        </td>
                                                        <td className="px-4 py-3 text-center">{tag?.position}</td>
                                                        <td className="px-4 py-3 text-center">
                                                            <div className='flex justify-around items-center'>
                                                                <DeleteOutline
                                                                    onClick={() => DeleteTagDetail(index)}
                                                                    className='cursor-pointer'
                                                                />
                                                                <Edit
                                                                    onClick={() => StartEditTagDetail(tag, index)}
                                                                    className='cursor-pointer'
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                                <div className="space-y-2 mt-4">
                                    <div className="space-y-2">
                                        <Label>Package Position</Label>
                                        <input
                                            type="number"
                                            className='w-full rounded border-[1.5px] border-stroke bg-transparent py-2 px-5 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-white disabled:text-gray-400 disabled:font-light'
                                            placeholder="Enter Position"
                                            name="position"
                                            onChange={(e: any) => {
                                                setFormData({
                                                    ...formData,
                                                    position: e.target.value
                                                })
                                            }}
                                            value={formData.position}
                                        />
                                    </div>
                                </div>


                            </div>
                        </div>


                        <div className="space-y-2">
                        {props?.packageData?.togglesAndPricings?.map((toggle, index) => (
                                <div className='flex flex-col gap-2 mt-2'>
                                    <div className='text-md font-semibold text-gray-900'>
                                        {toggle?.toggleValue}
                                    </div>
                                    <div className='grid grid-cols-1 md:grid-cols-4 gap-2 items-start'>
                                        <div className="space-y-1">
                                            <Label>Price</Label>
                                            <input
                                                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-white disabled:text-gray-400 disabled:font-light"
                                                type="number"
                                                placeholder="Enter Price"
                                                value={formData.groupBundle[index]?.price}
                                                name='price'
                                                onChange={(e) => {
                                                    HandleGroupBundle(index, e.target.value, 'price')
                                                }}
                                            />
                                        </div>
                                        <div className="space-y-1">
                                            <Label># Users</Label>
                                            <input
                                                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-white disabled:text-gray-400 disabled:font-light"
                                                type="number"
                                                placeholder="Enter Number of Users"
                                                value={formData.groupBundle[index]?.numberOfUsers}
                                                name='numberOfUsers'
                                                onChange={(e) => {
                                                    HandleGroupBundle(index, e.target.value, 'numberOfUsers')
                                                }}
                                            />
                                        </div>
                                        <div className="space-y-1">
                                            <Label>DPU</Label>
                                            <input
                                                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-white disabled:text-gray-400 disabled:font-light"
                                                type="number"
                                                placeholder="Enter Discount Per User"
                                                value={formData.groupBundle[index]?.discountPerUser}
                                                name='discountPerUser'
                                                onChange={(e) => {
                                                    HandleGroupBundle(index, e.target.value, 'discountPerUser')
                                                }}
                                            />
                                        </div>
                                        <div className="space-y-1">
                                            <Label>Active</Label>
                                            <Select
                                                style={{ width: "100%", height: '45px' }}
                                                placeholder="Select Active"
                                                value={formData.groupBundle[index]?.active}
                                                onChange={(value) => {
                                                    const updatedGroupBundle = formData.groupBundle.map((group, i) => {
                                                        if (i === index) {
                                                            return {
                                                                ...group,
                                                                active: value
                                                            }
                                                        }
                                                        return group
                                                    })
                                                    setFormData({
                                                        ...formData,
                                                        groupBundle: updatedGroupBundle
                                                    })
                                                }}
                                            >
                                                <Select.Option key='true' value={true}>
                                                    True
                                                </Select.Option>
                                                <Select.Option key='false' value={false}>
                                                    False
                                                </Select.Option>
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            </div>






                    </div>


                    <div className="flex items-center justify-end gap-2">
                        <ButtonOutlined
                            handleClick={() => {
                                props?.setModal(false);
                            }}
                        >
                            Cancel
                        </ButtonOutlined>

                        <ButtonFill handleClick={Submit} isLoading={loading}>
                            {props?.mode === 'add' ? 'Add' : 'Edit'}{" "} Pricing Plan
                        </ButtonFill>
                    </div>


                </div>


            </div>
        </div>
    )
}

const RibbonTags = [
    "Best Value",
    "Popular",
    "Recommended",
    "Limited Time",
    "New",
    "Exclusive",
    "Special Offer",
    "Hot Deal",
    "Best Seller",
    "Top Rated",
    "Top Seller",
    "Top Pick",
]

const Logos = [
    "Red",
    "Green",
    "Yellow",
]