import React from "react";
import { ButtonOutlined } from "shared-components/ui/CustomButtons";
import { Label } from "shared-components/ui/CustomForm";

export const ViewQuestionModal = ({ isOpen, onClose, question }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg w-[800px] max-h-[90vh] overflow-y-auto">
        <h2 className="text-xl font-bold mb-4">View Question</h2>

        <div className="space-y-4">
          <div>
            <Label>Question Text</Label>
            <div
              className="mt-1 p-3 bg-gray-50 rounded-md"
              dangerouslySetInnerHTML={{ __html: question.question_text }}
            />
          </div>

          <div>
            <Label>Answer Text</Label>
            <div
              className="mt-1 p-3 bg-gray-50 rounded-md"
              dangerouslySetInnerHTML={{ __html: question.answer_text }}
            />
          </div>

          <div>
            <Label>Description</Label>
            <div className="mt-1 p-3 bg-gray-50 rounded-md">
              {question.description || "N/A"}
            </div>
          </div>

          <div>
            <Label>Reference</Label>
            <div className="mt-1 p-3 bg-gray-50 rounded-md">
              {question.reference || "N/A"}
            </div>
          </div>

          <div>
            <Label>Tags</Label>
            <div className="flex flex-wrap gap-2 mt-1">
              {question.tags?.length > 0 ? (
                question.tags.map((tag, index) => (
                  <span
                    key={index}
                    className="bg-gray-100 px-3 py-1 rounded-full text-sm"
                  >
                    {tag}
                  </span>
                ))
              ) : (
                <span className="text-gray-500">No tags</span>
              )}
            </div>
          </div>

          <div>
            <Label>Image</Label>
            <div className="mt-1">
              {question.image ? (
                <img
                  src={question.image}
                  alt="Question"
                  className="max-h-48 rounded-lg"
                />
              ) : (
                <div className="p-3 bg-gray-50 rounded-md text-gray-500">
                  No image
                </div>
              )}
            </div>
          </div>

          <div>
            <Label>Difficulty</Label>
            <div className="flex gap-2 mt-1">
              {[1, 2, 3, 4, 5].map((level) => (
                <div
                  key={level}
                  className={`w-8 h-8 rounded flex items-center justify-center ${
                    question.difficulty === level
                      ? "bg-primary text-white"
                      : "bg-gray-100 text-gray-700"
                  }`}
                >
                  {level}
                </div>
              ))}
            </div>
          </div>

          <div>
            <Label>Extra Information</Label>
            <div className="mt-1 p-3 bg-gray-50 rounded-md">
              {question.extra || "N/A"}
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-6">
          <ButtonOutlined type="button" handleClick={onClose}>
            Close
          </ButtonOutlined>
        </div>
      </div>
    </div>
  );
};
