import React, { useRef, useState } from "react";
import { Upload, X } from "lucide-react";

interface FileUploadProps {
  onFileSelect: (file: File | null) => void;
  accept?: string;
  maxSize?: number;
  value?: string;
  previewType?: "image" | "video" | "pdf";
  className?: string;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  onFileSelect,
  accept = "image/*",
  maxSize = 5242880, // 5MB
  value,
  previewType = "image",
  className = "",
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [localFile, setLocalFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // Validate file type based on previewType
      const isValidType =
        (previewType === "image" && file.type.startsWith("image/")) ||
        (previewType === "video" && file.type.startsWith("video/")) ||
        (previewType === "pdf" && file.type === "application/pdf");

      if (!isValidType) {
        alert(`Please upload a valid ${previewType.toUpperCase()} file`);
        return;
      }

      if (file.size > maxSize) {
        alert(
          `File size should not exceed ${Math.round(maxSize / 1024 / 1024)}MB`
        );
        return;
      }

      setLocalFile(file);
      onFileSelect(file);
    }
  };

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleRemove = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setLocalFile(null);
    onFileSelect(null);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const previewUrl =
    value || (localFile ? URL.createObjectURL(localFile) : undefined);

  return (
    <div className={`w-full ${className}`}>
      <div
        onClick={handleClick}
        className="border-2 rounded-lg p-4 text-center cursor-pointer transition-colors hover:border-primary-500 bg-white relative"
      >
        <input
          ref={inputRef}
          type="file"
          accept={
            previewType === "image"
              ? "image/*"
              : previewType === "video"
              ? "video/*"
              : previewType === "pdf"
              ? ".pdf"
              : accept
          }
          onChange={handleFileChange}
          className="hidden"
        />

        {previewUrl ? (
          <div className="space-y-2">
            {previewType === "image" && (
              <img
                src={previewUrl}
                alt="Preview"
                className="mx-auto max-h-32 rounded-lg"
              />
            )}
            {previewType === "video" && (
              <video
                src={previewUrl}
                controls
                className="mx-auto max-h-32 rounded-lg"
              />
            )}
            {previewType === "pdf" && (
              <div className="relative">
                <iframe
                  src={previewUrl}
                  title="PDF Preview"
                  className="mx-auto max-h-64 w-full rounded-lg border"
                  style={{ minHeight: "200px" }}
                />
              </div>
            )}
            <div className="flex justify-between items-center">
              <p className="text-sm text-gray-500 flex-grow text-center">
                {localFile?.name || "Uploaded file"}
              </p>
              <button
                onClick={handleRemove}
                className="text-red-500 hover:bg-red-50 rounded-full p-1"
                title="Remove file"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
          </div>
        ) : (
          <div className="space-y-2">
            <Upload className="mx-auto h-12 w-12 text-gray-400" />
            <div className="text-sm text-gray-600 text-center">
              <p>Click to select a file</p>
              {previewType === "pdf" && (
                <p className="text-xs text-gray-500">PDF files only</p>
              )}
              {previewType === "image" && (
                <p className="text-xs text-gray-500">Image files only</p>
              )}
              {previewType === "video" && (
                <p className="text-xs text-gray-500">Video files only</p>
              )}
            </div>
            <p className="text-xs text-gray-500 text-center">
              Maximum file size: {Math.round(maxSize / 1024 / 1024)}MB
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
