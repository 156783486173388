import { CommissionAPI } from "(apis)/(shared-apis)/latest-commission-api";
import { Select } from "antd";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { ButtonFill, ButtonOutlined } from "shared-components/ui/CustomButtons";
import CustomDropDown from "shared-components/ui/CustomDropDown";
import { MAX_COMMISSION, ROlES } from "shared-pages/latest-commission/utils";

const CouponModal = ({ isModalOpen, closeModal, allUsers, modal = null }) => {
  const [isLoading, setIsLoading] = useState(false);
  const initialState = {
    codeType: "CA Code",
    couponCode: "",
    discount: "",
    specificUserEmail: "",
    forSpecificUser: false,
    expiryDate: "",
    isPromoCode: false,
    canExpire: false,
    commissionPercentage: 0,
    isfreetrial: false,
    couponUser: {
      name: "",
      email: "",
      role: "",
      userId: "",
    },
    role: "ASSOCIATE",
  };
  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    if (modal && modal?.data) {
      const data = modal?.data;
      const userEmail = modal?.data?.couponUser?.email;
      const user = getCouponUser(userEmail);
      if (user) {
        setFormData({
          ...data,
          couponUser: {
            ...data?.couponUser,
            email: userEmail,
          },
          role: user?.role || "",
        });
      }
    }
  }, [modal]);

  const resetForm = () => {
    setFormData(initialState);
    closeModal(false);
  };

  const generateAutoCode = () => {
    const randomCode = `COUPON-${Math.floor(Math.random() * 100)}`;
    setFormData({ ...formData, couponCode: randomCode });
    // toast.success("Code generated!");
  };

  const getCouponUser = (email: string) => {
    const user = allUsers?.find((item: any) => item?.email === email);
    return user || null;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!formData.couponCode || !formData.discount) {
      toast.error("Please fill in all fields.");
      return;
    }

    if (formData?.role === ROlES.MANAGER) {
      if (formData?.commissionPercentage > MAX_COMMISSION) {
        toast.error("Invalid Commission");
        return;
      }
    }

    setIsLoading(true);
    const data = formData;
    const getCouponUser = allUsers?.find(
      (item) => item?.email === formData?.couponUser?.email
    );
    if (getCouponUser && formData?.codeType === "CA Code") {
      data.couponUser = {
        email: getCouponUser?.email,
        name: getCouponUser?.name,
        role: getCouponUser?.role,
        userId: getCouponUser?.userId,
      };
    } else {
      data.couponUser = null;
      data.isPromoCode = true;
    }

    if (formData?.expiryDate?.trim() === "") {
      data.expiryDate = null;
    }
    if (formData?.specificUserEmail?.trim() === "") {
      data.specificUserEmail = null;
    }
    // console.log("Data: ", data);
    // Process formData submission here
    // return;
    try {
      let body: any = data;

      const response = await CommissionAPI("addCoupon", [], body);
      // console.log("Response: ", response);
      if (response?.data?.success) {
        toast.success("Code created successfully.", {
          duration: 3000,
          position: "top-center",
          style: {
            background: "#4CAF50",
            color: "#fff",
            zIndex: 10000,
          },
        });
        resetForm();
      }
    } catch (error) {
      // console.error("Error creating coupon:", error);
      // console.log("error: ", error);
      const message =
        error?.response?.data?.message || "Failed to create coupon.";
      toast.error(message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  return (
    <>
      <Toaster
        position="top-center"
        toastOptions={{
          style: {
            zIndex: 10000,
          },
        }}
      />
      <div
        className={`${
          isModalOpen ? "" : "hidden"
        } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000] flex justify-center items-center w-full h-screen bg-gray-200/40 backdrop-blur-sm`}
      >
        <div className="bg-white rounded-lg p-8 w-full max-w-lg">
          <h2 className="text-xl font-bold mb-4">Create Coupon Code</h2>

          <form onSubmit={handleSubmit}>
            {/* Code Type Selection */}
            <label className="block mb-2 font-semibold">Code Type</label>
            <select
              name="codeType"
              value={formData.codeType}
              onChange={handleInputChange}
              disabled={isLoading}
              className="w-full mb-4 p-2 border rounded"
            >
              <option value="CA Code">CA Code</option>
              <option value="Promo Code">Promo Code</option>
            </select>

            {/* Coupon Code */}
            <label className="block mb-2 font-semibold">Enter Code</label>
            <div className="flex gap-2 mb-4">
              <input
                type="text"
                name="couponCode"
                value={formData.couponCode}
                onChange={handleInputChange}
                placeholder="Enter or generate code"
                className="w-full p-2 border rounded"
                disabled={isLoading}
              />

              <ButtonOutlined handleClick={() => generateAutoCode()} disabled={isLoading}>
                Generate
              </ButtonOutlined>
            </div>

            {/* Discount */}
            <label className="block mb-2 font-semibold">Discount (%)</label>
            <input
              type="number"
              name="discount"
              value={formData.discount}
              onChange={handleInputChange}
              placeholder="Enter discount"
              className="w-full mb-4 p-2 border rounded"
              disabled={isLoading}
            />

            {/* Conditional Fields for Promo Code */}
            {formData.codeType === "Promo Code" && (
              <>
                {/* Discount */}
                <label className="block mb-2 font-semibold">
                  Commission (%)
                </label>
                <input
                  type="number"
                  name="commissionPercentage"
                  value={formData.commissionPercentage}
                  onChange={handleInputChange}
                  placeholder="Enter commission"
                  className="w-full mb-4 p-2 border rounded"
                  disabled={isLoading}
                />

                {/* For Specific User */}
                <div className="flex items-center gap-2 mb-4">
                  <input
                    type="checkbox"
                    name="forSpecificUser"
                    checked={formData.forSpecificUser}
                    onChange={handleInputChange}
                    disabled={isLoading}
                  />
                  <label>For Specific User?</label>
                </div>

                {/* User Email if Specific User is checked */}
                {formData.forSpecificUser && (
                  <>
                    <label className="block mb-2 font-semibold">
                      User Email
                    </label>
                    <input
                      type="email"
                      name="specificUserEmail"
                      value={formData.specificUserEmail}
                      onChange={handleInputChange}
                      placeholder="Enter user email"
                      className="w-full mb-4 p-2 border rounded"
                      disabled={isLoading}
                    />
                  </>
                )}

                {/* Can Expire */}
                <div className="flex items-center gap-2 mb-4">
                  <input
                    type="checkbox"
                    name="canExpire"
                    checked={formData.canExpire}
                    onChange={handleInputChange}
                    disabled={isLoading}
                  />
                  <label>Can Expire?</label>
                </div>

                {/* Expiry Date if Can Expire is checked */}
                {formData.canExpire && (
                  <>
                    <label className="block mb-2 font-semibold">
                      Expiry Date & Time
                    </label>
                    <input
                      type="datetime-local"
                      name="expiryDate"
                      value={formData.expiryDate}
                      onChange={handleInputChange}
                      className="w-full mb-4 p-2 border rounded"
                      disabled={isLoading}
                    />
                  </>
                )}
              </>
            )}

            {/* Conditional Fields for Promo Code */}
            {formData.codeType === "CA Code" && (
              <>
                {/* User Email if Specific User is checked */}

                <>
                  <label className="block mb-2 font-semibold">User Email</label>
                  <Select
                    showSearch
                    placeholder="Select Email"
                    onChange={(selectedValue) => {
                      const user = getCouponUser(selectedValue);
                      if (user) {
                        setFormData({
                          ...formData,
                          couponUser: {
                            ...formData?.couponUser,
                            email: selectedValue,
                          },
                          role: user?.role || "",
                        });
                      }
                    }}
                    size={"large"}
                    disabled={isLoading}
                    className="w-full mb-4"
                    value={formData?.couponUser?.email}
                  >
                    {allUsers?.map((item, index) => (
                      <Select.Option key={index} value={item?.email}>
                        {item?.email}
                      </Select.Option>
                    ))}
                  </Select>

                  <div className="flex items-center space-x-3 w-full">
                    <CustomDropDown
                      label="Role"
                      options={["ASSOCIATE", "MANAGER", "DIRECTOR"]}
                      onSelect={() => {}}
                      disabled={true}
                      width="w-full"
                      value={formData.role}
                      isError={false}
                      errorMessage={""}
                    />
                  </div>

                  {formData?.role === ROlES.MANAGER && (
                    <>
                      <label className="block mb-2 mt-3 font-semibold">
                        Commission (%)
                      </label>
                      <input
                        type="number"
                        name="commissionPercentage"
                        value={formData.commissionPercentage}
                        onChange={handleInputChange}
                        placeholder="Enter commission"
                        className="w-full mb-4 p-2 border rounded"
                        disabled={isLoading}
                      />
                    </>
                  )}
                </>
              </>
            )}

            {/* Is Free User */}
            {/* {formData.codeType === "CA Code" && ( */}
            <div className="flex items-center gap-2 my-4">
              <input
                type="checkbox"
                name="isfreetrial"
                checked={formData.isfreetrial}
                onChange={handleInputChange}
                disabled={isLoading}
              />
              <label htmlFor="isfreetrial">Free Trial</label>
            </div>
            {/* )} */}

            {/* Submit Button */}

            <div className="flex justify-center items-center space-x-4">
              <ButtonOutlined handleClick={() => resetForm()} disabled={isLoading}>
                Cancel
              </ButtonOutlined>
              <ButtonFill type="Submit" disabled={isLoading}>
                {isLoading ? "Creating..." : "Submit"}
              </ButtonFill>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CouponModal;
