import React, { useContext, useEffect, useState } from "react";
import CustomAntdModal from "../../shared-components/modal/CustomAntdModal";
import { DatePicker, Input, Button, Checkbox, Divider } from "antd";
import dayjs from 'dayjs';
import toast, { Toaster } from "react-hot-toast";
import { backendUrl } from "constants/config";
import axios from "axios";
import { UserContext } from "state-management/user-context";
import { User, Phone, Mail, MapPin, School } from 'lucide-react';
import { LocalPostOffice } from "@material-ui/icons";

interface ViewScholarshipProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  clickedInfoData: any;
  fetchData: () => void;
}

const ViewScholarship: React.FC<ViewScholarshipProps> = ({
  isOpen,
  setIsOpen,
  clickedInfoData,
  fetchData
}) => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("Navigation must be used within a UserProvider");
  }
  const { user } = context;

  const [form, setForm] = useState({
    id: clickedInfoData._id,
    email: clickedInfoData.Email,
    skuIds: [],
    duration: "",
    customTags: "",
    approvedBy: user?.username,
    amount: clickedInfoData.Amount
  });

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (form.duration === "" || form.customTags === "") {
      toast.error("Please fill all the fields");
      return;
    }
    if (form.amount === "" || form.amount === null || form.amount === undefined || Number(form.amount) === 0 || form.amount === "0" || Number(form.amount) < 0) {
      toast.error("Please enter valid amount");
      return;
    }
    if (form.skuIds.length === 0) {
      toast.error("Please give access to at least one option");
      return;
    }
    setLoading(true);
    const newForm = { ...form };
    newForm.customTags = newForm.customTags.trim();
    newForm.approvedBy = user?.username;
    newForm.id = clickedInfoData._id;
    newForm.email = clickedInfoData.Email;
    newForm.amount = Number(newForm.amount);

    try {
      const response = await axios.put(`${backendUrl}/AddApprove`, newForm);
      if (response.status === 200) {
        toast.success("Scholarship Approved");
        setIsOpen(false);
        fetchData();
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
    setLoading(false);
  };

  const handleReject = async () => {
    setLoading(true);
    try {
      const response = await axios.put(`${backendUrl}/RejectPending`, {
        id: clickedInfoData._id,
        email: clickedInfoData.Email,
        approvedBy: user?.username
      });
      if (response.status === 200) {
        toast.success("Scholarship Rejected");
        setIsOpen(false);
        fetchData();
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
    setLoading(false);
  };

  const [optionsPlan, setOptionsPlan] = useState<string[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${backendUrl}/api/packages/get-all-boards-and-corresponding-packages-names`);
      const data = response.data;
      const boards = data.boards;
      let tempOptions = [];
      for (const board in boards) {
        boards[board].forEach((sku) => {
          if (sku.SKUName && sku.SKUName !== "") {
            if (sku.SKUName.includes("Basic") || sku.SKUName.includes("BASIC")) {
              tempOptions.push(`${board} -> ${sku.SKUName} -> ${sku._id}`);
            }
          }
        });
      }
      setOptionsPlan(tempOptions);
    };
    fetchData();
  }, []);


  const [readMore, setReadMore] = useState({
    reason: false,
    whyPremed: false
  });

  return (
    <div>
      <CustomAntdModal
        isOpenModal={isOpen}
        setIsOpenModal={setIsOpen}
        title="Student Information"
        isFooter={false}
        okButtonProps={{ type: "primary" }}
      >
        <Toaster />
        <div className="flex flex-col md:flex-row gap-6">
          {/* Student Info Card */}
          <div className="w-full md:w-1/3 bg-gray-50 rounded-xl p-6">
            <div className="flex items-center space-x-3 mb-6">
              <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                <User className="w-6 h-6 text-blue-600" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900">{clickedInfoData?.Fname} {clickedInfoData?.Lname}</h3>
                <p className="text-sm text-gray-500">Applicant</p>
              </div>
            </div>

            <div className="space-y-4">
              <div className="flex items-center space-x-3">
                <Phone className="w-5 h-5 text-gray-400" />
                <span className="text-gray-700">{clickedInfoData?.PhoneNumber}</span>
              </div>
              
              <div className="flex items-center space-x-3">
                <Mail className="w-5 h-5 text-gray-400" />
                <span className="text-gray-700 text-sm">{clickedInfoData?.Email}</span>
              </div>
              
              <div className="flex items-center space-x-3">
                <MapPin className="w-5 h-5 text-gray-400" />
                <span className="text-gray-700">{clickedInfoData?.City}</span>
              </div>


             
              <div className="flex items-center space-x-3">
                <School className="w-5 h-5 text-gray-400" />
                <p className="text-sm text-gray-700">{clickedInfoData?.College}</p>
              </div>


              <div className="border-t pt-4 mt-4">
                <h4 className="text-sm font-medium text-gray-500 mb-2">Academic Grades</h4>
                <div className="space-y-2">
                  {Object.entries(clickedInfoData?.Grades || {}).map(([subject, grade]) => (
                    <div key={subject} className="flex justify-between">
                      <span className="text-sm font-medium">{subject.toUpperCase()}</span>
                      <span className="text-sm text-gray-600">{String(grade)}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="border-t pt-4 mt-4">
                <h4 className="text-sm font-medium text-gray-500 mb-2">Dream University</h4>
                <p className="text-sm text-gray-700">{clickedInfoData?.Academy}</p>
              </div>

              <Divider orientation="left">Supporting Documents</Divider>
              <div className="grid grid-cols-1 gap-6">
                <div>
                  <h4 className="text-sm font-medium text-gray-500 mb-3">Financial Documents</h4>
                  <div className="flex flex-wrap gap-2">
                    {clickedInfoData?.financialDocuments?.map((doc: string, key: number) => (
                      <a
                        key={key}
                        href={doc}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block"
                      >
                        <Button type="primary">View Document {key + 1}</Button>
                      </a>
                    ))}
                  </div>
                </div>

                <div>
                  <h4 className="text-sm font-medium text-gray-500 mb-3">Academic Documents</h4>
                  <div className="flex flex-wrap gap-2">
                    {clickedInfoData?.academicDocuments?.map((doc: string, key: number) => (
                      <a
                        key={key}
                        href={doc}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block"
                      >
                        <Button type="primary">View Document {key + 1}</Button>
                      </a>
                    ))}
                  </div>
                </div>
              </div>

              
            </div>
          </div>

          {/* Application Details */}
          <div className="w-full md:w-2/3 space-y-6">
            <div className="bg-white rounded-lg">
              <Divider orientation="left">Reasons for Applying</Divider>
              <p className="text-gray-700 mb-6">
                {readMore?.reason ? clickedInfoData?.Reason : clickedInfoData?.Reason?.slice(0, 150) + "..."}
                <Button type="link" onClick={() => setReadMore({ ...readMore, reason: !readMore?.reason })}>
                  {readMore?.reason ? "Read Less" : "Read More"}
                </Button>
              </p>

              <Divider orientation="left">Why PreMed.pk</Divider>
              <p className="text-gray-700 mb-6">
                  {readMore.whyPremed ? clickedInfoData?.WhyPremed : clickedInfoData?.WhyPremed?.slice(0, 150) + "..."}
                <Button type="link" onClick={() => setReadMore({ ...readMore, whyPremed: !readMore?.whyPremed })}>
                  {readMore?.whyPremed ? "Read Less" : "Read More"}
                </Button>
              </p>


              
              {clickedInfoData?.Status === "Pending" && (
              <>

              <Divider orientation="left">Package Selection</Divider>
              <div className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                  {optionsPlan.map((option, key) => (
                    <div key={key} className="flex items-center space-x-2 p-3 border rounded-lg hover:bg-gray-50">
                      <Checkbox
                        checked={form.skuIds.includes(option.split(" -> ")[2])}
                        onChange={(e) => {
                          const skuId = option.split(" -> ")[2];
                          setForm({
                            ...form,
                            skuIds: e.target.checked
                              ? [...form.skuIds, skuId]
                              : form.skuIds.filter(id => id !== skuId),
                          });
                        }}
                      />
                      <span className="text-sm">{option.split(" -> ")[1]}</span>
                    </div>
                  ))}
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                  <DatePicker
                    value={form.duration ? dayjs(form.duration) : null}
                    onChange={(date, dateString) => setForm({ ...form, duration: dateString[0] })}
                    className="w-full"
                    placeholder="Select Duration"
                  />
                  <Input
                    value={form.customTags}
                    onChange={(e) => setForm({ ...form, customTags: e.target.value })}
                    placeholder="Example: Till end of 2025 Exam"
                  />
                  
                  <Input
                    value={form.amount}
                    onChange={(e) => setForm({ ...form, amount: e.target.value })}
                    placeholder="Amount"
                    type="number"
                  />
                </div>
              </div>

              {/* Documents Section */}
     
              {/* Action Buttons */}
              <div className="flex justify-end space-x-4 mt-6 pt-6 border-t">
                <Button 
                  danger
                  onClick={handleReject}
                  disabled={loading}
                >
                  Reject
                </Button>
                <Button
                  type="primary"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Accept
                  </Button>
                </div>
              </>
              )}
            </div>
          </div>
        </div>
      </CustomAntdModal>
    </div>
  );
};

export default ViewScholarship;