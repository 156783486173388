import axios from "axios";
import { backendUrl } from "../../constants/config";
import { ApiInterface } from "interfaces/global-interfaces";

 export const FreeTrialApis:ApiInterface[] = [
    {
        url: "/api/free-trial/create-free-trial-code",
        method: "POST",
        name: "createFreeTrialCode",
    },
    {
        url: "/api/free-trial/handle-free-trial-code",
        method: "PUT",
        name: "handleFreeTrialCode",
    },
    {
        url: "/api/free-trial/get-free-trial-code",
        method: "GET",
        name: "getFreeTrialCode",
    },
    {
        url: "/api/free-trial/get-free-trial-leads",
        method: "GET",
        name: "getFreeTrialLeads",
    },
]


export function FreeTrialAPI(name: string, params: string[] = [""], data: any = null) {
    const api = FreeTrialApis.find((api) => api.name === name);
    if (!api) {
      throw new Error("API not found");
    }
  
    const url = backendUrl + api.url + (params ? "/" + params.join("/") : "");
  
  
    return axios({
      method: api.method,
      data,
      withCredentials: true,
      url
    });
  }
