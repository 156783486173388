import { useEffect, useState } from "react"
import { Select, Input } from "antd"
import { CopyIcon, LoaderIcon } from "lucide-react"
import React from "react"
import { FreeTrialAPI } from "(apis)/(shared-apis)/free-trial-apis"
import { AccessAPI } from "(apis)/(shared-apis)/admin-access-apis"
import { toast, Toaster } from "react-hot-toast"
import { apiRequest } from "(apis)/api-interface/api-handler"
import CustomFormField from "shared-components/form-fields/custom-form-field"
import { Link } from "react-router-dom"

type UserCode = {
  email: string
  code: string
  published?: boolean
  tag?: string[],
  type: "Days" | "End Date"
  days?: number
  endDate?: string
}

function generateCode(length: number): string {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
  let result = ""
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return result
}

const AxiosErrorHandler = (error: any, fallbackMessage: string) => {
  toast.error(error.response ? error.response.data.message : fallbackMessage)
}

export default function UserCodeTable() {
  const [userCodes, setUserCodes] = useState<UserCode[]>([])
  const [formData, setFormData] = useState<UserCode>({
    email: "",
    code: "",
    type: "Days",
    days: 0,
    endDate: "",
    tag: [],
  })
  const [searchTerm, setSearchTerm] = useState<string>("")

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    // Convert code to uppercase if the input is for code
    const processedValue = name === 'code' ? value.toUpperCase() : value
    setFormData((prev) => ({
      ...prev,
      [name]: processedValue,
    }))
  }

  const [accessTag, setAccessTag] = useState([]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await apiRequest("getBundleTags")
        setAccessTag(response?.data?.tags);
      } catch (err) {
        console.error("Error fetching tags:", err);
      }
    };

    fetchTags();
  }, []);

  const [userEmails, SetUserEmails] = useState([])
  const [isLoading, SetIsLoading] = useState(true)
  useEffect(() => {
    AccessAPI("getUsersEmails").then((res) => {
      SetUserEmails(res.data)
      SetIsLoading(false)
    })
  }, [])

  const [freeTrialCode, SetFreeTrialCode] = useState([])
  const [isLoading2, SetIsLoading2] = useState(true)
  const fetchFreeTrialCode = async () => {
    try {
      SetIsLoading2(true)

      const response = await FreeTrialAPI("getFreeTrialCode")
      if (response.data.success) {
        SetFreeTrialCode(response.data.data)
        SetIsLoading2(false)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      AxiosErrorHandler(error, "Failed to get free trial code")
    } finally {
      SetIsLoading2(false)
    }
  }
  useEffect(() => {
    fetchFreeTrialCode()
  }, []) // Added freeTrialCode to dependencies

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    toast.success("Adding user code. Please wait.")
    
    // Additional validation for code length
    if (formData.code.length === 4) {
      toast.error("Code cannot be 4 digits long")
      return;
    }

    if (formData.tag.length === 0) {
      toast.error("Please select at least one access tag")
      return;
    }
    if (formData.type === "Days" && formData.days === 0) {
      toast.error("Please enter a valid number of days")
      return;
    }
    if (formData.type === "End Date" && formData.endDate === "") {
      toast.error("Please enter a valid end date")
      return;
    }
    if (formData.type === "End Date" && new Date(formData.endDate) < new Date()) {
      toast.error("End date cannot be in the past")
      return;
    }

    try {
      const response = await FreeTrialAPI("createFreeTrialCode", [""], formData)
      if (response.data.success) {
        setUserCodes([...userCodes, formData])
        setFormData({ email: "", code: "", tag: [], type: "Days", days: 0, endDate: "" }) // Reset form
        toast.success("User code added successfully")
        fetchFreeTrialCode()
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      AxiosErrorHandler(error, "Failed to add user code")
    }
  }

  const copyCode = () => {
    navigator.clipboard.writeText(formData.code)
    toast.success("Code copied to clipboard")
  }

  const handleFreeTrialCode = async (code: string) => {
    toast.success("Publishing free trial code. Please wait.")
    try {
      const response = await FreeTrialAPI("handleFreeTrialCode", [], { code: code })
      if (response.data.success) {
        toast.success("Free trial code published successfully")
        fetchFreeTrialCode()
      }
    } catch (error) {
      AxiosErrorHandler(error, "Failed to publish free trial code")
    }
  }

  // Filter free trial codes based on search term
  const filteredFreeTrialCodes = freeTrialCode.filter((userCode: any) =>
    userCode?.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    userCode?.code.toLowerCase().includes(searchTerm.toLowerCase())
  )

  if (isLoading || isLoading2) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="flex flex-row items-center gap-2">
          <LoaderIcon className="w-4 h-4 animate-spin" />
          <p className="text-sm text-gray-500">Loading...</p>
        </div>
      </div>
    )
  }

  return (
    <div className='w-[95%] mx-auto'>
      <h2 className='font-bold text-2xl text-gray-800 mb-4 mt-4'>
        Free Trial Codes
      </h2>
      <Toaster />
      <form onSubmit={onSubmit} className="space-y-4">
        <div>
          <label htmlFor="email" className="block text-sm font-medium">
            Email
          </label>
          <Select
            options={userEmails.map((email) => ({ label: email, value: email }))}
            onChange={(value) => setFormData({ ...formData, email: value })}
            placeholder="Select User"
            style={{ width: "100%" }}
            className="w-full"
            value={formData.email}
            showSearch
            optionFilterProp="label"
            filterOption={(input: string, option: any) => option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            allowClear
          />
        </div>
        <div>
          <label htmlFor="tag" className="block text-sm font-medium">Access Tags</label>
          <Select
            mode="tags"
            className="flex-grow w-full"
            allowClear
            filterOption={(input: string, option: any) =>
              option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={accessTag.map(tag => ({ label: tag, value: tag }))}
            showSearch
            placeholder="Select Access Tags"
            onChange={(value) => setFormData({ ...formData, tag: value })}
            value={formData.tag || []}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="type" className="block text-sm font-medium">
              Type
            </label>
            <Select
              options={[{ label: "Days", value: "Days" }, { label: "End Date", value: "End Date" }]}
              onChange={(value) => {
                if (value === "Days") {
                  setFormData({ ...formData, type: "Days", days: 0, endDate: "" })
                } else {
                  setFormData({ ...formData, type: "End Date", days: 0, endDate: "" })
                }
              }}
              placeholder="Select Type"
              style={{ width: "100%" }}
              className="w-full"
              value={formData.type}
            />
          </div>
          {formData.type === "Days" ? (
            <div>
              <label htmlFor="days" className="block text-sm font-medium">
                Days
              </label>
              <Input
                type="number"
                min={1}
                max={365}
                value={formData.days}
                onChange={(e) => setFormData({ ...formData, days: parseInt(e.target.value), endDate: "" })}
                placeholder="Enter days"
                className="w-full"
              />
            </div>
          ) : (
            <div>
              <label htmlFor="endDate" className="block text-sm font-medium">
                End Date
              </label>
              <input
                type="date"
                value={formData.endDate}
                min={new Date().toISOString().split('T')[0]}
                placeholder="Select end date"
                className="w-full p-1 border rounded"
                onChange={(e) => setFormData({ ...formData, endDate: e.target.value, days: 0 })}
              />
            </div>
          )}

        </div>

        <div>
          <label htmlFor="code" className="block text-sm font-medium">
            Code
          </label>
          <div className="flex space-x-2">
            <input
              id="code"
              name="code"
              value={formData.code}
              onChange={handleInputChange}
              className="w-full p-1 border rounded text-sm"
              placeholder="Enter code (any length except 4 digits)"
            />
            <div className="flex items-center space-x-2">
              {formData.code && (
                <div className="flex items-center text-sm bg-white rounded-md p-1 shadow-lg">
                  <CopyIcon className="w-4 h-4 cursor-pointer" onClick={copyCode} />
                </div>
              )}
            </div>
          </div>
        </div>

        <button type="submit" className="px-2 py-1 bg-green-500 text-white rounded text-sm">
          Add
        </button>
      </form>

      <div className="mt-4 mb-4">
        <Input
          placeholder="Search by email or code"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full"
        />
      </div>

      <div className="mt-8 overflow-x-auto">
        <table className="w-full mb-20 text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-4">
                Email
              </th>
              <th scope="col" className="px-4 py-4">
                Code
              </th>
              <th scope="col" className="px-4 py-4">
                Published
              </th>
              <th scope="col" className="px-4 py-4">
                Leads
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredFreeTrialCodes.length > 0 ? (
              filteredFreeTrialCodes.map((userCode: any, index) => (
                <React.Fragment key={userCode._id}>
                  <tr className="border-b text-gray-800">
                    <td className="px-4 py-3">{userCode?.email}</td>
                    <td className="px-4 py-3">{userCode?.code}</td>
                    <td className="px-4 py-3">
                      <button
                        onClick={() => handleFreeTrialCode(userCode?.code)}
                        aria-label="Free Trial Code"
                        className="w-8 h-8 border-none bg-transparent shadow-none"
                      >
                        <input
                          type="checkbox"
                          checked={userCode?.published}
                          onChange={() => { }}
                          className="w-4 h-4 cursor-pointer"
                        />
                      </button>
                    </td>
                    <td className="p  x-4 py-3">
                      <Link to={`/manage/trial-leads/${userCode?.email}`}>
                        View Leads
                      </Link>
                    </td>
                  </tr>
                  {userCode?.access && userCode.access.length > 0 && (
                    <tr>
                      <td colSpan={3} className="px-4 py-2">
                        <div className="flex flex-wrap gap-2">
                          {userCode.access.map((access: string, accessIndex: number) => (
                            <span
                              key={accessIndex}
                              className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded"
                            >
                              {access}
                            </span>
                          ))}
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={4} className="px-6 py-4 whitespace-nowrap text-center">
                  No matching data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

