import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { FreeTrialAPI } from '../../(apis)/(shared-apis)/free-trial-apis';
import { UserContext } from 'state-management/user-context';
import { GetPlanNames, GetOwnCourses } from 'utility/form/essentials';

export default function TrialLeads() {
    const { email } = useParams();
    const [leads, setLeads] = useState([]);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const context = useContext(UserContext);
    if (!context) {
        throw new Error("Navigation must be used within a UserProvider");
    }
    const { user } = context;


    const GetLeads = async () => {
        try {
            setIsLoading(true);
            setError(null);
            const response = await FreeTrialAPI("getFreeTrialLeads", [email]);

            if (response.status === 200) {
                setLeads(response.data.data);
            } else {
                setError("Failed to fetch leads");
            }
        } catch (error) {
            console.error(error);
            setError("An error occurred while fetching leads");
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        GetLeads();
    }, [email]);



    if (user.username !== email) {
        return <div className='w-[95%] mx-auto p-4 text-center text-red-500 font-bold'>
            <div className='flex justify-center items-center gap-2 bg-gray-100 p-2 rounded-md'>
                Dosron ke leads dekhna mana hai.
            </div>
        </div>;
    }

    if (isLoading) {
        return <div className='w-[95%] mx-auto p-4 text-center'>Loading leads...</div>;
    }

    if (error) {
        return <div className='w-[95%] mx-auto p-4 text-red-500'>{error}</div>;
    }

    return (
        <div className='w-[95%] mx-auto'>
            <div className='p-4 flex flex-col gap-2'>
                <h2 className='font-bold text-2xl text-gray-800 mb-4 mt-4'>Leads for {email}</h2>

                {leads.length > 0 ? (
                    <>
                        {leads.map((lead: any) => (
                            <div key={lead._id} className='mb-6'>
                                <div className='bg-gray-100 p-2 mb-2 rounded'>
                                    Lead for: <span className='font-bold'>{lead.code}</span>
                                </div>
                                <div className="overflow-x-auto">
                                    <table className="w-full text-sm text-left text-gray-500 border border-gray-200 shadow-md rounded-lg">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 border-b">
                                            <tr>
                                                {['Name', 'Email', 'Phone', 'City', 'Purchases', 'Courses'].map((header) => (
                                                    <th key={header} scope="col" className="px-4 py-3">
                                                        {header}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {lead.students_availed.map((student: any) => (
                                                <tr key={student._id} className="border-b hover:bg-gray-50 transition-colors">
                                                    <td className="px-4 py-2">{student?.fullName || "N/A"}</td>
                                                    <td className="px-4 py-2">{student?.username || "N/A"}</td>
                                                    <td className="px-4 py-2">{student?.phonenumber || "N/A"}</td>
                                                    <td className="px-4 py-2">{student?.city || "N/A"}</td>
                                                    <td className="px-4 py-2 flex flex-wrap gap-2">{GetPlanNames(student).map((plan: any) => <span key={plan} className="bg-gray-200 text-gray-800 px-2 text-[9px] py-1 rounded-md text-sm">{plan}</span>)}</td>
                                                    <td className="px-4 py-2">{GetOwnCourses(student).join(", ") || "N/A"}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ))}
                    </>
                ) : (
                    <div className='text-gray-500 text-center p-4'>No leads found</div>
                )}
            </div>
        </div>
    )
}
