import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import CustomImageField from "shared-components/form-fields/custom-image-field";
import CustomPdfField from "shared-components/form-fields/custom-pdf-field";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";
import PaginationComponent from "./pagination-component";
import VaultCommonComponent from "./vault-common-component";

import { convertToFormData } from "utility/form/converttoformdata";
import identifyAndConvertNewImagesToBase64 from "utility/form/identifyAndConvertNewImagesToBase64";
import filteroutGeneratedImagesUrls from "utility/form/filteroutGeneratedImagesUrls";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { message } from "antd";
import { NoteSchema } from "../pages/notes/noteschema";
import { NoteInitialValues } from "../pages/notes/initialvalues";
import { cheatsheetSchema } from "../pages/cheatsheets/schema";
import { shortlistingSchema } from "../pages/shortlistings/schema";
import { mnemonicsSchema } from "../pages/mnemonics/schema";
import {
  CheatsheetInitialValues,
  MnemonicInitialValues,
  ShortlistingInitialValues,
} from "./initialvalues";
import { generateAntdImageObjectFromUrl } from "utility/form/generate-antd-image-object-from-url";
import { generateAntdPdfObjectFromUrl } from "utility/form/generate-antd-pdf-object-from-url";
import { getBase64ImageData } from "utility/form/functions";
import CustomFormField from "shared-components/form-fields/custom-form-field";
interface NoteModalProps {
  openModal: boolean;
  setOpenModal: (isOpen: boolean) => void;
  setIsEditMode: (isOpen: boolean) => void;
  isEditMode: boolean;
  isPagenation: boolean;
  vaultType: string;
  addApi: string;
  updateApi: string;

  fetchData: () => void;
  editData?: any;
}
const VaultModal: React.FC<NoteModalProps> = ({
  openModal,
  setOpenModal,
  isEditMode,
  isPagenation,
  vaultType,
  addApi,
  updateApi,
  fetchData,
  editData,
}) => {
  const [allSubTopicsData, setAllSubTopicsData] = useState([]);
  const formik = useFormik({
    initialValues:
      vaultType === "Note"
        ? NoteInitialValues
        : vaultType === "CheatSheet"
        ? CheatsheetInitialValues
        : vaultType === "Shortlisting"
        ? ShortlistingInitialValues
        : vaultType === "Mnemonic"
        ? MnemonicInitialValues
        : null,
    validationSchema:
      vaultType === "Note"
        ? NoteSchema
        : vaultType === "CheatSheet"
        ? cheatsheetSchema
        : vaultType === "Shortlisting"
        ? shortlistingSchema
        : vaultType === "Mnemonic"
        ? mnemonicsSchema
        : null,
    onSubmit: async (values: any) => {
      try {
        const base64Images =
          values?.thumbnailImage &&
          (await identifyAndConvertNewImagesToBase64(values?.thumbnailImage));
        const imageBase64 =
          values?.image &&
          (await identifyAndConvertNewImagesToBase64(values?.image));
        // Filter out pagination if no values are set
        const filteredPaginations = values.paginations?.filter(
          (pagination) => pagination.startPageNo && pagination.subTopic
        );

        const updatedValues = {
          ...values,
          image: imageBase64?.[0],
          imageUrl:
            values.pdfFile?.[0]?.originFileObj ||
            values.videoFile?.[0]?.originFileObj
              ? null
              : values.imageUrl,
          pdfUrl: imageBase64?.[0] ? null : values.pdfUrl,
          thumbnailImage: base64Images?.[0],
          thumbnailImageUrl: filteroutGeneratedImagesUrls(
            values?.thumbnailImage
          )?.[0],
          pdfFile: values.pdfFile?.[0]?.originFileObj,
          videoFile: values.videoFile?.[0]?.originFileObj,
          category: process.env.REACT_APP_CATEGORY,
          entity: process.env.REACT_APP_ENTITY,
          paginations:
            filteredPaginations?.length > 0 ? filteredPaginations : undefined,
        };
        console.log("updatedValues: ", updatedValues);
        // return;
        const formData = convertToFormData(updatedValues);
        let res = null;
        if (isEditMode) {
          res = await apiRequest(updateApi, formData, [values.editId]);
        } else {
          res = await apiRequest(addApi, formData);
        }
        if (res?.status === 200) {
          message.success(res?.data?.message);
          setOpenModal(false);
          formik.resetForm();
          fetchData();
        }
      } catch (error) {
        message.error(error.response?.data?.message || error.message);
        console.error(error);
      }
    },
  });
  useEffect(() => {
    if (isEditMode) {
      formik.resetForm();
      const { expandData, id, thumbnailImageUrl, pdfUrl, imageUrl } = editData;
      const thb = generateAntdImageObjectFromUrl(
        vaultType === "Mnemonic" ? editData?.thumbnailUrl : thumbnailImageUrl
      );
      const img = generateAntdImageObjectFromUrl(imageUrl);
      const pdfFormated = generateAntdPdfObjectFromUrl(pdfUrl);
      const videoObj = {
        uid: "-1",
        name: "video.mp4",
        status: "done",
        url: editData?.videoUrl,
        type: "video/mp4",
      };
      formik.setValues({
        ...editData,
        desc: expandData,
        editId: id,
        pdfFile: pdfUrl && [pdfFormated],
        image: pdfUrl || editData?.videoUrl ? "" : [img],
        imageUrl,
        thumbnailImage: [thb],
        videoFile: editData?.videoUrl ? [videoObj] : null,
      });
    } else {
      formik.resetForm();
    }
  }, [editData, isEditMode]);
  return (
    <div>
      <CustomAntdModal
        isOpenModal={openModal}
        title={isEditMode ? `Edit ${vaultType}` : `Add ${vaultType} `}
        setIsOpenModal={setOpenModal}
        formik={formik}
      >
        {vaultType === "Mnemonic" ? (
          <CustomFormField
            type="text"
            label="Mnemonic Name"
            name="mnemonicName"
            formik={formik}
            require={false}
          />
        ) : null}

        <VaultCommonComponent
          formik={formik}
          vaultType={vaultType}
          setAllSubTopicsData={setAllSubTopicsData}
        />
        <div className="flex  md:flex-row  w-full">
          <div
            className={`md:p-2 w-full ${
              vaultType !== "Note" ? "md:w-1/3" : "w-1/2"
            } `}
          >
            <CustomImageField
              label="Thumbnail"
              name="thumbnailImage"
              formik={formik}
            />
          </div>
          {vaultType === "Mnemonic" && (
            <div className="md:p-2 w-full md:w-1/3">
              <CustomImageField label="Image" name="image" formik={formik} />
            </div>
          )}

          <div
            className={`w-full  flex gap-3  ${
              vaultType !== "Note" ? "md:w-1/3" : "w-1/2"
            }`}
          >
            {vaultType === "Mnemonic" ? (
              <CustomFormField
                type="video"
                label="Video File"
                name="videoFile"
                formik={formik}
              />
            ) : (
              <CustomPdfField label="PDF File" name="pdfFile" formik={formik} />
            )}
          </div>
        </div>
        <div>
          {vaultType === "Mnemonic" && (
            <CustomFormField
              type="text"
              label="Video Link"
              name="videoLink"
              formik={formik}
            />
          )}
        </div>
        {isPagenation && (
          <PaginationComponent
            formik={formik}
            allSubTopicsData={allSubTopicsData}
          />
        )}
      </CustomAntdModal>
    </div>
  );
};

export default VaultModal;
