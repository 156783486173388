import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { ButtonFill, ButtonOutlined } from "shared-components/ui/CustomButtons";
import { Modal } from "antd";
import { Input } from "shared-components/ui/CustomForm";
import { FlashcardAPI } from "(apis)/(shared-apis)/flashcard-api";
import { Edit } from "lucide-react";

const FlashCardDeckManager = () => {
  const { id } = useParams();
  const [deck, setDeck] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchDeck = async () => {
    setLoading(true);
    try {
      const response = await FlashcardAPI("getDeckById", {}, [id]);
      console.log("response: ", response);
      if (response?.data?.success) {        
        setDeck(response?.data?.data);
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong";
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDeck();
  }, []);

  const [isCreateSubjectModalOpen, setIsCreateSubjectModalOpen] = useState(false);
  const [newSubject, setNewSubject] = useState({
    deck_id: id,
    subject_name: "",
    description: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleCreateSubject = async () => {
    // e.preventDefault();
    setIsLoading(true);
    try {
      const response = await FlashcardAPI("createSubject", newSubject);
      if (response?.data?.success) {
        toast.success("Subject created successfully");
        setNewSubject({
          deck_id: id,
          subject_name: "",
          description: "",
        });
        setIsCreateSubjectModalOpen(false);
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong";
      toast.error(message);
    } finally {
      setIsLoading(false);
    }
  };

  const navigate = useNavigate();

  if (loading) {
    return <div>Loading deck...</div>;
  }

  if (!deck) {
    return <div>Deck not found</div>;
  }

  return (
    <div className="w-full pb-20 px-5">
      <Toaster />
      <div className="bg-white p-6 rounded-lg shadow-md mb-6 flex justify-between items-center">
        <div>
          <h2 className="text-3xl font-bold text-gray-800 mb-1">
            {deck.deck_name}
          </h2>
          <p className="text-sm text-gray-500">
            Manage your flashcard deck questions
          </p>
        </div>
        <div
          className={`px-4 py-2 rounded-full ${
            deck.is_published
              ? "bg-green-100 text-green-800"
              : "bg-yellow-100 text-yellow-800"
          }`}
        >
          {deck.is_published ? "Published" : "Unpublished"}
        </div>
      </div>

      <div className="flex justify-end mb-5">
      <ButtonFill handleClick={() => setIsCreateSubjectModalOpen(true)}>
            Add Subject
          </ButtonFill>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full mb-20 text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-4">
                Subject Name
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Description
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Questions
              </th>
              <th scope="col" className="px-4 py-3 text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {deck.subjects.map((subject) => (
              <tr className="border-b text-gray-800" key={subject._id}>
                <td className="px-4 py-3">{subject.subject_name}</td>
                <td className="px-4 py-3">{subject.description}</td>
                <td className="px-4 py-3">{subject.questions.length}</td>
                <td className="px-4 py-3">
                  <Edit className="w-4 h-4 text-gray-500 cursor-pointer" 
                    onClick={() => {
                      navigate(`/manage/flashcards/subject/${subject._id}`);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal
        title="Create New Subject"
        open={isCreateSubjectModalOpen}
        onCancel={() => setIsCreateSubjectModalOpen(false)}
        footer={[
          <div className="flex justify-end items-center space-x-2 mt-12">
          <ButtonOutlined
            key="back"
            handleClick={() => setIsCreateSubjectModalOpen(false)}
          >
            Cancel
          </ButtonOutlined>,
          <ButtonFill
            key="submit"
            handleClick={handleCreateSubject}
            disabled={!newSubject.subject_name}
          >
              Create Subject
            </ButtonFill>
          </div>,
        ]}
      >
        <div className="space-y-4">
          <Input
            type="text"
            placeholder="Enter subject name"
            value={newSubject.subject_name}
            onChange={(name, value) =>
              setNewSubject((prev) => ({ ...prev, subject_name: value }))
            }
          />

          <Input
            type="text"
            placeholder="Enter subject description (optional)"
            value={newSubject.description}
            onChange={(name, value) =>
              setNewSubject((prev) => ({ ...prev, description: value }))
            }
          />
        </div>
      </Modal>


    </div>
  );
};

export default FlashCardDeckManager;
