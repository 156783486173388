import React, { useState, useEffect } from 'react'
import { FlashcardAPI } from '(apis)/(shared-apis)/flashcard-api';

export default function Page() {
    const [flashcardsReport, setFlashcardsReport] = useState([]);
    const [selectedReport, setSelectedReport] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('ALL');
    const [uniqueStatuses, setUniqueStatuses] = useState(['ALL']);

    useEffect(() => {
        const fetchFlashcardsReport = async () => {
            const response = await FlashcardAPI("getFlashcardsReport");
            if (response.data.statusCode === 200) {
                setFlashcardsReport(response.data.data);
                // Get unique statuses from the report data
                const statuses = ['ALL', ...Array.from(new Set(response.data.data.map(report => report.status)))];
                setUniqueStatuses(statuses as string[]);
            }
        };
        fetchFlashcardsReport();
    }, []);

    const filteredReports = flashcardsReport.filter(report => 
        selectedStatus === 'ALL' ? true : report.status === selectedStatus
    );

    const handleViewDetails = (report) => {
        setSelectedReport(report);
        setIsModalOpen(true);
    };

    return (
        <div className="w-[95%] mx-auto pb-20 px-5">
            <div className="flex items-center justify-between pb-4 mt-5">
                <h2 className="text-2xl font-bold text-gray-700">Flashcards Report</h2>
            </div>

            {/* Status Tabs */}
            <div className="flex space-x-2 mb-4">
                {uniqueStatuses.map((status) => (
                    <button
                        key={status}
                        onClick={() => setSelectedStatus(status)}
                        className={`px-4 py-2 rounded-lg ${
                            selectedStatus === status
                                ? 'bg-blue-600 text-white'
                                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                        }`}
                    >
                        {status}
                    </button>
                ))}
            </div>

            <div className="bg-white shadow-md sm:rounded-lg">
                <div className="overflow-x-auto">
                    <table className="w-full mb-20 text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th scope="col" className="px-4 py-4">
                                    Created At
                                </th>
                                <th scope="col" className="px-4 py-4">
                                    Updated At
                                </th>
                                <th scope="col" className="px-4 py-4 text-left">
                                    User Name
                                </th>
                                <th scope="col" className="px-4 py-4 text-left">
                                    Question
                                </th>
                                <th scope="col" className="px-4 py-4 text-center">
                                    Report Text
                                </th>
                                <th scope="col" className="px-4 py-3 text-center">
                                    Status
                                </th>
                                <th scope="col" className="px-4 py-3 text-center">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredReports.map((report, index) => (
                                <tr key={report._id} className="bg-white border-b hover:bg-gray-50">
                                    <td className="px-4 py-3">
                                        {new Date(report.createdAt).toLocaleDateString()}
                                    </td>
                                    <td className="px-4 py-3">
                                        {new Date(report.updatedAt).toLocaleDateString()}
                                    </td>
                                    <td className="px-4 py-3">
                                        {report.user?.fullname || 'N/A'}
                                    </td>
                                    <td className="px-4 py-3">
                                        {report.question_id?.question_text || 'N/A'}
                                    </td>
                                    <td className="px-4 py-3 text-center">
                                        {report.report_text || 'N/A'}
                                    </td>
                                    <td className="px-4 py-3 text-center">
                                        <span className={`px-2 py-1 rounded-full text-xs ${
                                            report.status === 'PENDING' ? 'bg-yellow-100 text-yellow-800' : 
                                            report.status === 'RESOLVED' ? 'bg-green-100 text-green-800' : 
                                            'bg-gray-100 text-gray-800'
                                        }`}>
                                            {report.status}
                                        </span>
                                    </td>
                                    <td className="px-4 py-3 text-center">
                                        <button
                                            onClick={() => handleViewDetails(report)}
                                            className="text-blue-600 hover:text-blue-800 font-medium"
                                        >
                                            View Details
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Modal/Popup */}
            {isModalOpen && selectedReport && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg p-6 max-w-2xl w-full mx-4 max-h-[90vh] overflow-y-auto">
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="text-xl font-bold text-gray-900">Report Details</h3>
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="text-gray-500 hover:text-gray-700"
                            >
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        
                        <div className="space-y-4">
                            <div>
                                <h4 className="font-semibold text-gray-700">User Information</h4>
                                <p>Name: {selectedReport.user?.fullname || 'N/A'}</p>
                                <p>Email: {selectedReport.user?.username || 'N/A'}</p>
                                <p>Phone: {selectedReport.user?.phonenumber || 'N/A'}</p>
                            </div>
                            
                            <div>
                                <h4 className="font-semibold text-gray-700">Question Details</h4>
                                <p>Question: {selectedReport.question_id?.question_text || 'N/A'}</p>
                                <p>Answer: {selectedReport.question_id?.answer_text || 'N/A'}</p>
                                <p>Reference: {selectedReport.question_id?.reference || 'N/A'}</p>
                            </div>
                            
                            <div>
                                <h4 className="font-semibold text-gray-700">Report Information</h4>
                                <p>Report Text: {selectedReport.report_text}</p>
                                <p>Status: {selectedReport.status}</p>
                                <p>Issues: {selectedReport.issues?.join(', ') || 'N/A'}</p>
                                <p>Created: {new Date(selectedReport.createdAt).toLocaleString()}</p>
                                <p>Updated: {new Date(selectedReport.updatedAt).toLocaleString()}</p>
                            </div>
                        </div>

                        <div className="mt-6 flex justify-end">
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

