import { PlusOutlined } from "@ant-design/icons";
import { useFormik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { validationSchema } from "./schema";
import { apiRequest } from "(apis)/api-interface/api-handler";

import EssentialModal from "./modal";
import EssentialTable from "./table";
import CustomButton from "shared-components/form-fields/custom-button";
import Loader from "shared-components/Loader";
import { essentialStuffInitialValues } from "shared-pages/vault/components/initialvalues";
import { message } from "antd";
import { EssentialStuffValues } from "./types";
import { convertToFormData } from "utility/form/converttoformdata";
import identifyAndConvertNewImagesToBase64 from "utility/form/identifyAndConvertNewImagesToBase64";
import useModules from "shared-pages/vault/components/useModules";
import toast, { Toaster } from 'react-hot-toast';

const ESSENTIAL_CATEGORIES = [
  "Study Schedules",
  "Syllabi",
  "High-Impact Study Material",
  "Official Exam Essentials",
];

const EssentialStuff: React.FC = () => {
  type SelectOption = { value: string; label: string }[];
  const [showModel, setShowModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [boardsData, setBoardData] = useState<SelectOption>([]);
  const [allSubTopicsData, setAllSubTopicsData] = useState<SelectOption>([]);
  const [essentialStuffData, setEssentialStuffData] = useState<any[]>([]);
  const [institutesData, setInstitutesData] = useState<SelectOption>([]);
  const [categoriesData, setCategoriesData] = useState<SelectOption>([]);
  const [essentialCategoriesData, setEssentialCategoriesData] =
    useState<SelectOption>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [modules, setModules] = useState([]);
  // const { modules } = useModules();
  const formik: FormikProps<EssentialStuffValues> =
    useFormik<EssentialStuffValues>({
      initialValues: essentialStuffInitialValues,
      validationSchema,
      onSubmit: async (values) => {
        const base64Image = await identifyAndConvertNewImagesToBase64(
          values?.image
        );
        const getFileUrls = (files: any[], isOld: boolean) =>
          files
            ?.filter((file) => file?.isOldFile === isOld)
            ?.map((file) => (isOld ? file?.url : file?.originFileObj));
        const imageUrl = getFileUrls(values?.image || [], true);
        const pdfUrl = getFileUrls(values?.pdf || [], true);
        const filteredPaginations = values.paginations?.filter(
          (pagination) => pagination.startPageNo && pagination.endPageNo
        );
        const updatedValues = {
          ...values,
          pdf: values?.pdf?.[0]?.originFileObj,
          imageUrl: imageUrl?.[0],
          image: base64Image?.[0],
          pdfUrl: pdfUrl?.[0],
          category: process.env.REACT_APP_CATEGORY,
          essential_category: values.essential_category || "",
          entity: process.env.REACT_APP_ENTITY,
          paginations:
            filteredPaginations?.length > 0 ? filteredPaginations : undefined,
        };

        const formData = convertToFormData(updatedValues);

        try {
          let res;
          if (isEditMode) {
            res = await apiRequest("updateessential", formData, [
              values?.essentialId,
            ]);
          } else {
            res = await apiRequest("addessential", formData);
          }
          if (res?.status === 200) {
            setLoading(false);
            setIsEditMode(false);
            setShowModel(false);
            getEssentialStuffData();
            message.success(res?.data?.message);
            resetForm();
          }
        } catch (error) {
          console.log("error: ", error);
          const errorMessage = error?.response?.data?.message || error.message;
          toast.error(
            errorMessage === "Request failed with status code 500"
              ? "This error might be due to larger image or pdf size. Please try to compress them."
              : errorMessage
          );
          // message.error(errorMessage);
          setLoading(false);
        }
      },
    });

  const { resetForm } = formik;

  const getEssentialStuffData = async () => {
    try {
      setLoading(true);
      const res = await apiRequest("getessentials");
      if (res?.data?.success) {
        const resData = res?.data?.data;
        setEssentialStuffData(resData);
        setLoading(false);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error.message);
      console.error(error);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);

      // Call the consolidated API
      const response = await apiRequest("getAllEssentailsAndModalDropDownData");

      // Check for a successful response
      if (response?.status === 200 && response?.data?.isSuccess) {
        const { topics, boards, institutes, essentialData, modules } =
          response.data.data;

        // Set essential data
        if (essentialData) {
          setEssentialStuffData(essentialData);
        }

        // Set essential categories data
        const essentialCategoriesOptions = ESSENTIAL_CATEGORIES.map(
          (category) => ({
            value: category,
            label: category,
          })
        );
        setEssentialCategoriesData(essentialCategoriesOptions);

        // Set institutes data
        if (institutes) {
          const instituteData = institutes.map((item) => ({
            value: item?.instituteName,
            label: item?.instituteName,
          }));
          setInstitutesData(instituteData);
        }
        if (modules?.length > 0) {
          const modulesData = modules.map((item) => ({
            value: item?.moduleName,
            label: item?.moduleName,
          }));
          setModules(modulesData);
        }

        // Set topics data and extract subtopics
        if (topics?.length > 0) {
          let allSubTopics = [];
          topics.forEach((topic) => {
            topic?.subTopics?.forEach((subTopic) => {
              allSubTopics.push(subTopic);
            });
          });
          const formattedAllSubTopics = allSubTopics.map((subTopic) => ({
            value: subTopic.subTopicName,
            label: subTopic.subTopicName,
          }));
          setAllSubTopicsData(formattedAllSubTopics);
        }

        // Set boards data
        if (boards?.length > 0) {
          const boardData = boards.map((item) => ({
            value: item?.board,
            label: item?.board,
          }));
          setBoardData(boardData);
        }
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEssentialIsPublish = async (
    { id },
    isFreeForMarketing = false
  ) => {
    try {
      const res = await apiRequest(
        "updateessentialpublish",
        { isFreeForMarketing },
        [id]
      );
      if (res?.data?.success) {
        message.success(res?.data?.message);
        getEssentialStuffData();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error.message);
    }
  };
  return (
    <div>
      <Loader loading={loading} />
      <Toaster/>
      <div className="mx-4">
        <div className="flex justify-between mx-10">
          <div className="text-xl font-bold">Essential Stuff</div>
          <CustomButton
            icon={<PlusOutlined />}
            text="Add New Essential Stuff"
            onClick={() => {
              setShowModel(true);
              setIsEditMode(false);
              formik.resetForm();
            }}
          />
        </div>
        <EssentialTable
          essentialStuffData={essentialStuffData}
          setIsEditMode={setIsEditMode}
          setShowModel={setShowModel}
          formik={formik}
          loading={loading}
          isEditMode={isEditMode}
          ShowModel={showModel}
          handleEssentialIsPublish={handleEssentialIsPublish}
        />
      </div>
      <EssentialModal
        formik={formik}
        institutesData={institutesData}
        isEditMode={isEditMode}
        ShowModel={showModel}
        loading={formik.isSubmitting}
        boardsData={boardsData}
        allSubTopicsData={allSubTopicsData}
        setIsEditMode={setIsEditMode}
        setShowModel={setShowModel}
        modules={modules}
        categoriesData={categoriesData}
        essentialCategoriesData={essentialCategoriesData}
      />
    </div>
  );
};

export default EssentialStuff;
