import { useState } from "react";
import { CommissionAPI } from "../../../../(apis)/(shared-apis)/latest-commission-api";
import { ButtonFill } from "../../../../shared-components/ui/CustomButtons";
import CustomDropDown from "../../../../shared-components/ui/CustomDropDown";
import {
  ExternalLinkIcon,
  SearchIcon,
} from "../../../../shared-components/ui/Icons";
import { CODE_TYPES, renderChips } from "../../utils";

import CouponModal from "./coupon-modal";
import AllowedEmailsModal from "./AllowedEmailsModal";

const ViewCoupons = ({ allUsers, coupons, setCoupons }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectCodeType, setCodeType] = useState(CODE_TYPES?.CA_Code);
  const [selectRole, setSelectRole] = useState("All");
  const [selectActiveStatus, setSelectActiveStatus] = useState("All");
  const [isLoading, setIsLoading] = useState(false);

  const [loadingCoupons, setLoadingCoupons] = useState<{
    [key: string]: boolean;
  }>({});
  const [statusMessage, setStatusMessage] = useState<{
    [key: string]: { message: string; type: "success" | "error" };
  }>({});

  const [confirmationModal, setConfirmationModal] = useState({
    isOpen: false,
    data: null,
    isLoading: false,
  });

  const [allowedEmailsModal, setAllowedEmailsModal] = useState({
    isOpen: false,
    couponId: null,
    existingEmails: [],
  });

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSelectStatusChange = (selectedStatus) => {
    setCodeType(selectedStatus);
  };

  const filteredData = coupons?.filter((item) => {
    // Check if code type matches or if "All" is selected
    const isCodeTypeMatch =
      selectCodeType === "All" || item.codeType === selectCodeType;

    // Check if role matches or if "All" is selected, or if codeType is Promo_Code (bypass role check)
    const isRoleMatch =
      selectRole === "All" ||
      selectCodeType === CODE_TYPES.Promo_Code ||
      (item?.couponUser?.role && selectRole === item.couponUser.role);

    // Check if active status matches
    const isActiveStatusMatch =
      selectActiveStatus === "All" ||
      (selectActiveStatus === "Active" && item.isActive) ||
      (selectActiveStatus === "Inactive" && !item.isActive);

    // Search by email or name, ensuring couponUser exists
    const isSearchMatch =
      selectCodeType === CODE_TYPES.Promo_Code ||
      item?.couponUser?.email
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase()) ||
      item?.couponUser?.name
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase()) ||
      item?.couponCode?.toLowerCase()?.includes(searchQuery?.toLowerCase());

    // Return true if all conditions match
    return (
      isCodeTypeMatch && isRoleMatch && isSearchMatch && isActiveStatusMatch
    );
  });

  const handleActionButton = (item) => {
    // setModalData(reimbursement);
    // setIsModalOpen(true);
    window.open(`/manage/commission/coupon/${item?._id}`, "_blank");
  };

  const [modal, setModal] = useState({
    isModalOpen: false,
    data: null,
  });

  const closeModal = () => {
    setModal({
      isModalOpen: false,
      data: null,
    });
  };

  const UpdateStatus = async (couponId: string, currentStatus: boolean) => {
    try {
      // Set loading state for this specific coupon
      setLoadingCoupons((prev) => ({ ...prev, [couponId]: true }));

      const response = await CommissionAPI("updateCouponStatus", [], {
        couponId: couponId,
        isActive: !currentStatus,
      });

      console.log("Response: ", response);

      if (response?.data?.success) {
        // Only update the local state if the API call is successful
        setCoupons((prevCoupons: any) =>
          prevCoupons.map((coupon: any) =>
            coupon?._id?.toString() === couponId?.toString()
              ? { ...coupon, isActive: !currentStatus }
              : coupon
          )
        );

        // Set success status message
        setStatusMessage((prev) => ({
          ...prev,
          [couponId]: {
            message: `Coupon ${
              !currentStatus ? "activated" : "deactivated"
            } successfully`,
            type: "success",
          },
        }));

        // Remove status message after 2 seconds
        setTimeout(() => {
          setStatusMessage((prev) => {
            const newMessages = { ...prev };
            delete newMessages[couponId];
            return newMessages;
          });
        }, 2000);
      } else {
        // Set error status message
        setStatusMessage((prev) => ({
          ...prev,
          [couponId]: {
            message:
              response?.data?.message || "Failed to update coupon status",
            type: "error",
          },
        }));

        // Remove status message after 2 seconds
        setTimeout(() => {
          setStatusMessage((prev) => {
            const newMessages = { ...prev };
            delete newMessages[couponId];
            return newMessages;
          });
        }, 2000);

        // Revert any premature UI changes
        return;
      }
    } catch (error) {
      console.error("Error updating coupon status:", error);

      // Set error status message
      setStatusMessage((prev) => ({
        ...prev,
        [couponId]: {
          message: "Failed to update coupon status",
          type: "error",
        },
      }));

      // Remove status message after 2 seconds
      setTimeout(() => {
        setStatusMessage((prev) => {
          const newMessages = { ...prev };
          delete newMessages[couponId];
          return newMessages;
        });
      }, 2000);
    } finally {
      // Remove loading state for this specific coupon
      setLoadingCoupons((prev) => {
        const newLoadingCoupons = { ...prev };
        delete newLoadingCoupons[couponId];
        return newLoadingCoupons;
      });
    }
  };

  const handleStatusChangeConfirmation = (item) => {
    setConfirmationModal({
      isOpen: true,
      data: item,
      isLoading: false,
    });
  };

  const confirmStatusChange = async () => {
    if (confirmationModal.data) {
      // Set loading state for confirmation modal
      setConfirmationModal((prev) => ({
        ...prev,
        isLoading: true,
      }));

      try {
        await UpdateStatus(
          confirmationModal.data?._id,
          confirmationModal.data?.isActive
        );
      } finally {
        // Close the confirmation modal and reset loading state
        setConfirmationModal({
          isOpen: false,
          data: null,
          isLoading: false,
        });
      }
    }
  };

  const cancelStatusChange = () => {
    setConfirmationModal({
      isOpen: false,
      data: null,
      isLoading: false,
    });
  };

  const handleManageAllowedEmails = (item) => {
    // Only allow for Promo Codes
    if (item.codeType === CODE_TYPES?.Promo_Code) {
      setAllowedEmailsModal({
        isOpen: true,
        couponId: item?._id,
        existingEmails: item?.allowedEmails || [],
      });
    }
  };

  const handleUpdateAllowedEmails = (updatedEmails) => {
    // Update the local coupons state with the new allowed emails
    setCoupons((prevCoupons) =>
      prevCoupons.map((coupon) =>
        coupon?._id === allowedEmailsModal.couponId
          ? { ...coupon, allowedEmails: updatedEmails }
          : coupon
      )
    );
  };

  const handleOpenAllowedEmailsModal = () => {
    // Only open for Promo Codes
    if (selectCodeType === CODE_TYPES?.Promo_Code) {
      const promoCodeItem = filteredData.find(
        (item) => item.codeType === CODE_TYPES?.Promo_Code
      );

      if (promoCodeItem) {
        setAllowedEmailsModal({
          isOpen: true,
          couponId: promoCodeItem?._id,
          existingEmails: promoCodeItem?.allowedEmails || [],
        });
      }
    }
  };

  const handleCloseAllowedEmailsModal = () => {
    setAllowedEmailsModal((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  return (
    <>
      {/* =========================== */}
      {/* =========================== */}
      {/*  Coupon Modal*/}
      <CouponModal
        isModalOpen={modal?.isModalOpen}
        allUsers={allUsers}
        closeModal={closeModal}
        modal={modal}
      />
      {/* =========================== */}
      {/* =========================== */}

      <div className="w-full pb-20 px-5">
        <section className="my-5 antialiased">
          <div className="mx-auto">
            {isLoading ? (
              "Loading..."
            ) : (
              <div className="bg-white shadow-md sm:rounded-lg">
                <div className="">
                  <div className="flex items-center p-4 space-x-3">
                    <div className="relative w-full flex-grow">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <SearchIcon color="text-gray-400" />
                      </div>
                      <input
                        type="text"
                        id="simple-search"
                        className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-none outline-none"
                        placeholder="Search by Order ID or Email"
                        value={searchQuery}
                        onChange={handleSearchQueryChange}
                      />
                    </div>
                    <div className="min-w-fit">
                      <ButtonFill
                        handleClick={() => {
                          setModal({
                            isModalOpen: true,
                            data: null,
                          });
                        }}
                      >
                        Add new code
                      </ButtonFill>
                    </div>
                    {selectCodeType === CODE_TYPES?.Promo_Code && (
                      <div className="min-w-fit">
                        <ButtonFill handleClick={handleOpenAllowedEmailsModal}>
                          Manage Promo Emails
                        </ButtonFill>
                      </div>
                    )}
                  </div>
                  <div className="flex items-end p-4 space-x-3">
                    <CustomDropDown
                      // disabled
                      label="Status"
                      options={[CODE_TYPES?.Promo_Code, CODE_TYPES?.CA_Code]}
                      onSelect={(selectedStatus) =>
                        handleSelectStatusChange(selectedStatus.toString())
                      }
                      width="min-w-[12rem]"
                      value={selectCodeType}
                    />

                    {selectCodeType === CODE_TYPES?.CA_Code && (
                      <CustomDropDown
                        label="Role"
                        options={["All", "ASSOCIATE", "MANAGER", "DIRECTOR"]}
                        onSelect={(selectedStatus) =>
                          setSelectRole(selectedStatus)
                        }
                        width="min-w-[12rem]"
                        value={selectRole}
                      />
                    )}

                    <CustomDropDown
                      label="Status"
                      options={["All", "Active", "Inactive"]}
                      onSelect={(selectedStatus) =>
                        setSelectActiveStatus(selectedStatus)
                      }
                      width="min-w-[12rem]"
                      value={selectActiveStatus}
                    />
                  </div>
                </div>
                <table className="w-full mb-20 text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="px-4 py-4">
                        Code
                      </th>
                      {selectCodeType === CODE_TYPES?.CA_Code && (
                        <>
                          <th scope="col" className="px-4 py-4">
                            Email
                          </th>
                          <th scope="col" className="px-4 py-4">
                            Role
                          </th>
                        </>
                      )}
                      {selectCodeType === CODE_TYPES?.Promo_Code && (
                        <th scope="col" className="px-4 py-4">
                          Allowed Emails
                        </th>
                      )}
                      <th scope="col" className="px-4 py-4">
                        Commission
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Discount
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Active
                      </th>
                      <th scope="col" className="px-4 py-4">
                        CreatedBy
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Date
                      </th>
                      {/* <th scope="col" className="px-4 py-4">
                        Edit
                      </th> */}
                      {selectCodeType === CODE_TYPES?.Promo_Code && (
                        <th scope="col" className="px-4 py-4">
                          View
                        </th>
                      )}
                      {selectCodeType === CODE_TYPES?.Promo_Code && (
                        <th scope="col" className="px-4 py-4">
                          Actions
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData &&
                      filteredData?.length > 0 &&
                      filteredData?.map((item) => (
                        <tr className="border-b" key={item?._id}>
                          <td className="px-4 py-3">{item?.couponCode}</td>
                          {selectCodeType === CODE_TYPES?.CA_Code && (
                            <>
                              <td className="px-4 py-3">
                                {item?.couponUser?.email}
                              </td>
                              <td className="px-4 py-3">
                                {renderChips(item?.couponUser?.role)}
                              </td>
                            </>
                          )}
                          {selectCodeType === CODE_TYPES?.Promo_Code && (
                            <td className="px-4 py-3">
                              <div className="flex flex-wrap gap-1 max-w-[200px]">
                                {item?.allowedEmails?.length > 0 ? (
                                  item.allowedEmails.map((email, index) => (
                                    <span
                                      key={index}
                                      className="bg-gray-100 px-2 py-1 rounded-full text-xs"
                                    >
                                      {email}
                                    </span>
                                  ))
                                ) : (
                                  <span className="text-gray-500 text-xs">
                                    No emails allowed
                                  </span>
                                )}
                              </div>
                            </td>
                          )}
                          <td className="px-4 py-3">
                            {item?.commissionPercentage}
                          </td>

                          <td className="px-4 py-3">{item?.discount}</td>

                          <td className="px-4 py-3">
                            <div className="flex flex-col items-start">
                              {loadingCoupons[item?._id] ? (
                                <span className="text-gray-500">
                                  Updating...
                                </span>
                              ) : (
                                <button
                                  onClick={() =>
                                    handleStatusChangeConfirmation(item)
                                  }
                                  disabled={loadingCoupons[item?._id]}
                                  className={`px-3 py-1 rounded-full text-sm font-medium ${
                                    loadingCoupons[item?._id]
                                      ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                                      : item?.isActive
                                      ? "bg-green-100 text-green-800 hover:bg-green-200"
                                      : "bg-red-100 text-red-800 hover:bg-red-200"
                                  }`}
                                >
                                  {loadingCoupons[item?._id]
                                    ? "Updating..."
                                    : item?.isActive
                                    ? "Active"
                                    : "Inactive"}
                                </button>
                              )}
                            </div>
                          </td>
                          <td className="px-4 py-3">{item?.createdBy}</td>
                          <td className="px-4 py-3">
                            {new Date(item.createdAt).toLocaleDateString(
                              "en-GB"
                            )}
                          </td>
                          {/* <td className="px-4 py-3">
                            <button
                              type="button"
                              className="flex items-center justify-center w-full text-gray-700 hover:bg-gray-100"
                              onClick={() => {
                                setModal({
                                  isModalOpen: true,
                                  data: item,
                                });
                              }}
                            >
                              <ExternalLinkIcon />
                            </button>
                          </td> */}
                          {selectCodeType === CODE_TYPES?.Promo_Code && (
                            <td className="px-4 py-3">
                              <button
                                className=""
                                onClick={() => {
                                  handleActionButton(item);
                                }}
                              >
                                <ExternalLinkIcon />
                              </button>
                            </td>
                          )}
                          {selectCodeType === CODE_TYPES?.Promo_Code && (
                            <td className="px-4 py-3">
                              <button
                                className="text-primary hover:underline"
                                onClick={() => {
                                  handleManageAllowedEmails(item);
                                }}
                              >
                                Manage Emails
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}

                    {/* {filteredData &&
                      filteredData?.length > 0 &&
                      filteredData?.map((reimbursement) => (
                        <tr className="border-b" key={reimbursement?._id}>
                          <td className="px-4 py-3">{reimbursement?.name}</td>
                          <td className="px-4 py-3">{reimbursement?.email}</td>
                          <td className="px-4 py-3">
                            {renderStatusChips(reimbursement?.status)}
                          </td>
                          <td className="px-4 py-3">
                            {renderChips(reimbursement.role)}
                          </td>
                          <td className="px-4 py-3">
                            {new Date(
                              reimbursement?.dateRequested
                            )?.toLocaleDateString()}
                          </td>
                          <td className="px-4 py-3">
                            {formatNumberWithCommas(
                              reimbursement?.requestedAmount
                            )}
                          </td>
                          <td className="px-4 py-3">
                            <button
                              className=""
                              onClick={() => {
                                handleActionButton(reimbursement);
                              }}
                            >
                              <ExternalLinkIcon />
                            </button>
                          </td>
                        </tr>
                      ))} */}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </section>
      </div>

      {/* Confirmation Modal for Status Change */}
      {confirmationModal.isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-sm w-full">
            <h2 className="text-xl font-semibold mb-4">
              Confirm Status Change
            </h2>
            <p className="mb-6">
              Are you sure you want to{" "}
              {confirmationModal.data?.isActive ? "deactivate" : "activate"}{" "}
              this coupon?
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={cancelStatusChange}
                disabled={confirmationModal.isLoading}
                className={`px-4 py-2 rounded-md ${
                  confirmationModal.isLoading
                    ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                    : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                }`}
              >
                Cancel
              </button>
              <button
                onClick={confirmStatusChange}
                disabled={confirmationModal.isLoading}
                className={`px-4 py-2 rounded-md text-white ${
                  confirmationModal.isLoading
                    ? "bg-gray-400 cursor-not-allowed"
                    : confirmationModal.data?.isActive
                    ? "bg-primary hover:bg-primary/80"
                    : "bg-green-600 hover:bg-green-700"
                }`}
              >
                {confirmationModal.isLoading ? "Updating..." : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      )}

      {allowedEmailsModal.isOpen &&
        selectCodeType === CODE_TYPES?.Promo_Code && (
          <AllowedEmailsModal
            isOpen={allowedEmailsModal.isOpen}
            couponId={allowedEmailsModal.couponId}
            existingEmails={allowedEmailsModal.existingEmails}
            onClose={handleCloseAllowedEmailsModal}
            onUpdate={handleUpdateAllowedEmails}
          />
        )}
    </>
  );
};

export default ViewCoupons;
