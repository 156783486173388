import React, { useState, useEffect } from "react";
import { ButtonFill, ButtonOutlined } from "shared-components/ui/CustomButtons";
import { Input } from "shared-components/ui/CustomForm";
import { FileUpload } from "shared-components/ui/file-upload";
import toast, { Toaster } from "react-hot-toast";
import { FlashcardAPI } from "(apis)/(shared-apis)/flashcard-api";
import { Modal, Select } from "antd";

const FlashCardDecksManager = () => {
  const [decks, setDecks] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isCreateSubjectModalOpen, setIsCreateSubjectModalOpen] =
    useState(false);
  const [newDeck, setNewDeck] = useState({
    deck_name: "",
    is_published: false,
    logo: null,
  });

  const handleCreateDeck = async () => {
    // e.preventDefault();
    setIsLoading(true);
    try {
      const response = await FlashcardAPI("createDeck", newDeck);

      if (response?.data?.success) {
        toast.success("Deck created successfully");
        setNewDeck({
          deck_name: "",
          is_published: false,
          logo: null,
        });
        setRefreshData((prev) => prev + 1);
        setIsCreateModalOpen(false);
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong";
      toast.error(message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogoUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setNewDeck((prev) => ({
        ...prev,
        logo: e.target.result as string,
      }));
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    fetchDecks();
  }, [refreshData]);

  const fetchDecks = async () => {
    setIsLoading(true);
    try {
      const response = await FlashcardAPI("getAllDecks");

      if (response?.data?.success) {
        setDecks(response.data.data);
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong";
      toast.error(message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full pb-20 px-5">
      <Toaster />

      <div className="flex items-center justify-between pb-1 mt-5">
        <h2 className="text-2xl font-bold text-gray-700">Deck Manager</h2>
        <div className="flex space-x-2">
          <ButtonFill handleClick={() => setIsCreateModalOpen(true)}>
            Create Deck
          </ButtonFill>
        </div>
      </div>

      <div className="flex items-center space-x-3 my-5">
        <Input
          type="text"
          placeholder="Search decks..."
          value={searchQuery}
          onChange={(name, value) => setSearchQuery(value)}
        />
      </div>

      {/* Create Deck Modal */}
      <Modal
        title="Create New Deck"
        open={isCreateModalOpen}
        onCancel={() => setIsCreateModalOpen(false)}
        footer={[
          <ButtonOutlined
            key="back"
            handleClick={() => setIsCreateModalOpen(false)}
          >
            Cancel
          </ButtonOutlined>,        
          <ButtonFill
            key="submit"
            handleClick={handleCreateDeck}
            disabled={!newDeck.deck_name}
          >
            Create Deck
          </ButtonFill>,
        ]}
      >
        <div className="space-y-4">
          <Input
            type="text"
            placeholder="Enter deck name"
            value={newDeck.deck_name}
            onChange={(name, value) =>
              setNewDeck((prev) => ({ ...prev, deck_name: value }))
            }
          />

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Deck Logo
            </label>
            <FileUpload
              onFileSelect={handleLogoUpload}
              value={newDeck.logo || undefined}
              previewType="image"
            />
          </div>

          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={newDeck.is_published}
              onChange={(e) =>
                setNewDeck((prev) => ({
                  ...prev,
                  is_published: e.target.checked,
                }))
              }
              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            />
            <label className="text-sm text-gray-900">Publish Deck</label>
          </div>
        </div>
      </Modal>

  
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-3">
                Logo
              </th>
              <th scope="col" className="px-4 py-3">
                Deck Name
              </th>
              <th scope="col" className="px-4 py-3">
                Subjects
              </th>
              <th scope="col" className="px-4 py-3">
                Status
              </th>
              <th scope="col" className="px-4 py-3 text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {decks?.map((deck) => (
              <tr key={deck._id} className="border-b">
                <td className="px-4 py-3">
                  {deck.logo ? (
                    <img
                      src={deck.logo}
                      alt="Deck Logo"
                      className="w-12 h-12 object-cover rounded-full"
                    />
                  ) : (
                    <div className="w-12 h-12 bg-gray-200 rounded-full"></div>
                  )}
                </td>
                <td className="px-4 py-3">{deck?.deck_name}</td>
                <td className="px-4 py-3">{deck?.subjects?.length}</td>
                <td className="px-4 py-3">
                  {deck?.is_published ? "Published" : "Unpublished"}
                </td>
                <td className="px-4 py-3 text-right space-x-2">
                  <ButtonOutlined
                    handleClick={() => {
                      window.open(
                        `/manage/flashcards/decks/${deck?._id}`,
                        "_blank"
                      );
                    }}
                  >
                    View
                  </ButtonOutlined>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default FlashCardDecksManager;
