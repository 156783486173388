import React from "react";
import { Edit2, Trash2, Copy } from "lucide-react";
import toast from "react-hot-toast";
import { Switcher } from "shared-components/ui/CustomForm";
import { ButtonFill } from "shared-components/ui/CustomButtons";

export const QuestionTable = ({
  questions,
  onEdit,
  onDelete,
  onPublishToggle,
  loadingStates = { deleting: {}, publishing: {} },
  showCheckboxes = false,
  onCheckboxChange,
  selectedQuestions = [],
  showPublishToggle = false,
  onSelectAll, // New prop for select all functionality
}) => {
  const handleCopyId = (id) => {
    navigator.clipboard
      .writeText(id)
      .then(() => {
        toast.success("Question ID copied to clipboard", {
          style: {
            background: "#333",
            color: "#fff",
          },
          iconTheme: {
            primary: "#4CAF50",
            secondary: "white",
          },
        });
      })
      .catch((err) => {
        toast.error("Failed to copy ID", {
          style: {
            background: "#ff4d4f",
            color: "#fff",
          },
        });
      });
  };

  return (
    <div>
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="px-2 py-3 w-[50px]">
              #
            </th>
            {showCheckboxes && (
              <th scope="col" className="px-2 py-3 w-[50px]">
                Select
              </th>
            )}
            <th scope="col" className="px-4 py-3">
              Question
            </th>
            <th scope="col" className="px-4 py-3">
              Answer
            </th>
            {showPublishToggle && (
              <th scope="col" className="px-4 py-3">
                Published
              </th>
            )}
            <th scope="col" className="px-4 py-3 text-right">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {questions.map((question, index) => (
            <tr key={question._id} className="border-b">
              <td className="px-2 py-3 text-center">{index + 1}</td>
              {showCheckboxes && (
                <td className="px-2 py-3 text-center">
                  <input
                    type="checkbox"
                    checked={selectedQuestions.includes(question._id)}
                    onChange={() => onCheckboxChange(question._id)}
                    className="form-checkbox h-4 w-4 text-primary-500"
                  />
                </td>
              )}
              <td className="px-4 py-3">
                <div
                  className="max-w-full truncate-multiline"
                  dangerouslySetInnerHTML={{ __html: question.question_text }}
                />
              </td>
              <td className="px-4 py-3">
                <div
                  className="max-w-full truncate-multiline"
                  dangerouslySetInnerHTML={{ __html: question.answer_text }}
                />
              </td>
              {showPublishToggle && (
                <td className="px-4 py-3">
                  <Switcher
                    isActive={undefined}
                    for={question._id}
                    onChange={() =>
                      onPublishToggle(question._id, question.is_published)
                    }
                    togglevalue={question.is_published}
                    size="small"
                    disabled={loadingStates.publishing[question._id]}
                  />
                </td>
              )}
              <td className="px-4 py-3 text-right space-x-2 flex justify-end items-center">
                <button
                  onClick={() => handleCopyId(question._id)}
                  className="text-gray-600 hover:text-green-500 transition-colors duration-200 mr-2"
                  title="Copy Question ID"
                >
                  <Copy size={20} />
                </button>
                <button
                  onClick={() => onEdit(question)}
                  className="text-gray-600 hover:text-blue-500 transition-colors duration-200 mr-2"
                  title="Edit"
                >
                  <Edit2 size={20} />
                </button>
                <button
                  onClick={() => onDelete(question._id)}
                  className={`
                    text-gray-600 
                    hover:text-red-500 
                    transition-colors 
                    duration-200 
                    ${
                      loadingStates.deleting[question._id]
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }
                  `}
                  title="Delete"
                  disabled={loadingStates.deleting[question._id]}
                >
                  {loadingStates.deleting[question._id] ? (
                    "Deleting..."
                  ) : (
                    <Trash2 size={20} />
                  )}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
