import { BasicGetApi } from "../../../(apis)/(shared-apis)/basic-get-apis";
import { CommissionAPI } from "../../../(apis)/(shared-apis)/latest-commission-api";
import { useEffect, useState } from "react";
import { CommissionBanner, GetTime, renderChips, ROlES } from "../utils";
import toast from "react-hot-toast";
import CustomBadges from "shared-components/ui/CustomBadges";
import { Spin } from "antd";

const Sales = () => {
  const [refreshData, setRefreshData] = useState(1);
  const [pendingSales, setPendingSales] = useState([]);
  const [isFetchingNestedStructure, setIsFetchingNestedStructure] =
    useState(false);

  async function fetchNestedData() {
    setIsFetchingNestedStructure(true);

    try {
      const [hierarchyResponse] = await Promise.all([
        CommissionAPI("getPendingSales"),
      ]);

      if (hierarchyResponse.status === 200) {
        const hierarchyData = hierarchyResponse?.data?.data || [];

        setPendingSales(hierarchyData);
      }
    } catch (error) {
      const message =
        error?.response?.data?.message || "Failed to fetch pending sales.";
      toast.error(message);
    } finally {
      setIsFetchingNestedStructure(false);
    }
  }

  useEffect(() => {
    if (refreshData) {
      fetchNestedData();
    }
  }, [refreshData]);

  if (isFetchingNestedStructure) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <Spin size="large" tip="Fetching pending sales..." />
      </div>
    );
  }

  return (
    <>
      <div className="w-full pb-20 px-5">
        <CommissionBanner
          title={`Pending Sales (${pendingSales?.length}) `}
          setRefreshData={setRefreshData}
        />

        {pendingSales?.length === 0 ? (
          <div className="flex flex-col items-center justify-center mt-20 text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-24 w-24 text-gray-300 mb-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <h2 className="text-2xl font-semibold text-gray-600 mb-2">
              No Pending Sales
            </h2>
            <p className="text-gray-500">
              There are currently no pending sales to display.
            </p>
          </div>
        ) : (
          <table className="w-full mt-10 mb-20 text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-4 py-4">
                  No
                </th>
                <th scope="col" className="px-4 py-4 text-left">
                  Date of Order
                </th>
                <th scope="col" className="px-4 py-4 text-left">
                  Purchased User Email
                </th>
                <th scope="col" className="px-4 py-4 text-left">
                  Products
                </th>

                <th scope="col" className="px-4 py-4 text-center">
                  User Paid Amount
                </th>
                <th scope="col" className="px-4 py-4 text-left">
                  Coupon
                </th>
                <th scope="col" className="px-4 py-4 text-left">
                  Order Status
                </th>
              </tr>
            </thead>
            <tbody>
              {pendingSales?.map((order: any, index: number) => {
                return (
                  <tr className="border-b" key={order?._id}>
                    <td className="px-4 py-3">{index + 1}</td>
                    <td className="px-4 py-3 text-left text-xs">
                      {order?.createdAt
                        ? GetTime(order?.createdAt) +
                          " " +
                          new Date(order?.createdAt)?.toLocaleDateString()
                        : "N/A"}
                    </td>
                    <td className="px-4 py-3 text-left">{order?.UserName}</td>
                    <td className="px-4 py-3  text-left text-sm">
                      {order?.PRODUCTS && order?.PRODUCTS?.length > 0
                        ? order?.PRODUCTS?.join(",")
                        : "N/A"}
                    </td>

                    <td className="px-4 py-3 text-center">
                      {order?.BREAKDOWN?.GrandTotal?.toFixed() || "N/A"}
                    </td>

                    <td className="px-4 py-3 text-left">
                      {renderChips(order?.CouponCode)}
                    </td>
                    <td className="px-4 py-3 text-left">
                      <CustomBadges
                        label="Pending"
                        type="warning"
                        showIcon={false}
                        textSize={"sm"}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default Sales;
