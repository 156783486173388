import { apiRequest } from "(apis)/api-interface/api-handler";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { message } from "antd";
import { FormikHelpers, useFormik } from "formik";
import { useState } from "react";
import CustomFormField from "shared-components/form-fields/custom-form-field";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";
import * as yup from "yup";

interface FormDialogProps {
  opened: boolean;
  departmentData: { id: string; name: string }[];
  setOpened: (opened: boolean) => void;
  editData?: any;
  isEditMode: boolean;
  fetchTeamMembers: () => void;
}

interface FormValues {
  Name: string;
  department: string;
  position: string;
  introduction: string;
  Order: string;
  publish: boolean;
  linkedin?: string;
  instagram?: string;
  facebook?: string;
  twitter?: string;
  image: File | null;
}

interface SnackbarState {
  isOpen: boolean;
  status: "success" | "error";
}

export default function FormDialog({
  opened,
  departmentData,
  setOpened,
  editData,
  isEditMode,
  fetchTeamMembers,
}: FormDialogProps) {
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    isOpen: false,
    status: "success",
  });

  const imageWidthAndHeight = (
    provideFile: File
  ): Promise<{ width: number | null; height: number | null }> => {
    const imgDimensions = { width: null, height: null };

    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.readAsDataURL(provideFile);
      reader.onload = function () {
        const img = new Image();
        img.src = reader.result as string;

        img.onload = function () {
          imgDimensions.width = img.width;
          imgDimensions.height = img.height;

          resolve(imgDimensions);
        };
      };
    });
  };

  yup.addMethod(
    yup.mixed,
    "imageDimensionCheck",
    function (message: string, requiredWidth: number, requiredHeight: number) {
      return this.test(
        "image-width-height-check",
        message,
        async function (value: any) {
          const { path, createError } = this;

          if (!value) {
            return true;
          }

          const imgDimensions = await imageWidthAndHeight(value);

          const minHeight = requiredHeight - 10;
          const maxHeight = requiredHeight + 5;

          if (imgDimensions.height === null || imgDimensions.width === null) {
            return createError({
              path,
              message: `Unable to retrieve image dimensions`,
            });
          }

          if (
            imgDimensions.height < minHeight ||
            imgDimensions.height > maxHeight
          ) {
            return createError({
              path,
              message: `The image height should be between ${minHeight} and ${maxHeight} pixels`,
            });
          }

          if (imgDimensions.width !== requiredWidth) {
            return createError({
              path,
              message: `The image width should be ${requiredWidth} pixels`,
            });
          }

          return true;
        }
      );
    }
  );

  function capitalizeWords(str: string): string {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const validationSchema = yup.object().shape({
    Name: yup.string().required("Name is required"),
    position: yup.string().required("Position is required"),
    department: yup.string().required("Department is required"),
    introduction: yup
      .string()
      .required("Introduction is required")
      .test(
        "wordCount",
        "Introduction must not exceed 100 words",
        function (value) {
          if (value) {
            const wordCount = value.trim().split(/\s+/).length;
            return wordCount <= 100;
          }
          return true;
        }
      ),
    Order: yup
      .number()
      .required("Order number is required")
      .positive(
        "Order can't be negative, only numbers greater than 0 are allowed"
      ),
    linkedin: yup.string().optional().url("Enter a valid URL"),
    facebook: yup.string().optional().url("Enter a valid URL"),
    instagram: yup.string().optional().url("Enter a valid URL"),
    twitter: yup.string().optional().url("Enter a valid URL"),
    image: yup
      .mixed()
      .test("fileFormat", "Invalid file format", function (value: any) {
        if (value) {
          const validTypes = ["image/jpeg", "image/jpg", "image/png"];
          if (!validTypes.includes(value.type)) {
            return false;
          }
        }
        return true;
      })
      .nullable(),
  });

  const handleClose = () => {
    setOpened(false);
    setIsMaleClicked(false);
    setIsFemaleClicked(false);
    setChosenOption("");
    formik.resetForm();
  };

  const handleSocial = (
    facebook?: string,
    linkedin?: string,
    instagram?: string,
    twitter?: string
  ) => {
    const socials: Record<string, string | undefined> = {};

    if (facebook) {
      socials.facebookURL = facebook;
    }
    if (linkedin) {
      socials.linkedInURL = linkedin;
    }
    if (instagram) {
      socials.instagramURL = instagram;
    }
    if (twitter) {
      socials.twitterURL = twitter;
    }
    return JSON.stringify(socials);
  };

  const [imageError, setImageError] = useState<string>("");

  const formik = useFormik<FormValues>({
    initialValues: {
      Name: "",
      department: "",
      position: "",
      introduction: "",
      Order: "",
      publish: true,
      linkedin: "",
      instagram: "",
      facebook: "",
      twitter: "",
      image: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }: FormikHelpers<FormValues>) => {
      if (!isFemaleClicked && !isMaleClicked && !values.image) {
        setImageError("Image is required");
        return;
      } else {
        setImageError("");
      }

      const socials = handleSocial(
        values.facebook,
        values.linkedin,
        values.instagram,
        values.twitter
      );

      const formdata = new FormData();
      formdata.append("name", capitalizeWords(values.Name));
      formdata.append("designation", values.position);
      formdata.append("department", values.department);
      formdata.append("aboutMe", values.introduction);
      formdata.append("order", values.Order);
      formdata.append("socials", socials);

      if (isMaleClicked) {
        const imgUrl =
          "https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/Premed_Avatar_male.png";
        formdata.append("imageURL", imgUrl);
      } else if (isFemaleClicked) {
        const imgUrl =
          "https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/Premed_Avatar_female.png";
        formdata.append("imageURL", imgUrl);
      } else if (values.image) {
        formdata.append("image", values.image);
      }

      try {
        const res = await apiRequest("addTeamMember", formdata);
        if (res?.status === 201) {
          message.success("Team member added successfully");
          fetchTeamMembers();
        }
      } catch (error) {
        setSnackbar((prev) => ({
          ...prev,
          status: "error",
        }));
      }
      setSnackbar((prev) => ({
        ...prev,
        isOpen: true,
      }));

      resetForm();
      handleClose();
    },
  });

  const [chosenOption, setChosenOption] = useState<string>("");
  const [isMaleClicked, setIsMaleClicked] = useState<boolean>(false);
  const [isFemaleClicked, setIsFemaleClicked] = useState<boolean>(false);

  const handleMaleClick = () => {
    setIsMaleClicked(true);
    setIsFemaleClicked(false);
  };

  const handleFemaleClick = () => {
    setIsFemaleClicked(true);
    setIsMaleClicked(false);
  };
  console.log("formik", formik?.errors);

  return (
    <div>
      <CustomAntdModal
        isOpenModal={opened}
        title={isEditMode ? `Edit Team Member` : `Add Team Member`}
        setIsOpenModal={setOpened}
        formik={formik}
      >
        <form
          id="login-form"
          className="flex flex-col"
          onSubmit={formik.handleSubmit}
        >
          <DialogContent>
            <div className="flex flex-col md:flex-row gap-2">
              <div className="w-full md:w-1/2">
                <CustomFormField
                  type="text"
                  formik={formik}
                  name="Name"
                  label="Name"
                />
              </div>
              <div className="w-full md:w-1/2">
                <CustomFormField
                  type="text"
                  formik={formik}
                  name="position"
                  label="Position"
                />
              </div>
            </div>
            <FormControl
              fullWidth
              style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
            >
              <InputLabel htmlFor="department">Department</InputLabel>
              <Select
                label="Department"
                id="department"
                name="department"
                value={formik.values.department}
                onChange={(e) => {
                  formik.handleChange(e);
                  setChosenOption(e.target.value);
                }}
                error={
                  formik.touched.department && Boolean(formik.errors.department)
                }
              >
                {departmentData.map((dept) => (
                  <MenuItem key={dept.id} value={dept.id}>
                    {dept.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <CustomFormField
              type="textarea"
              formik={formik}
              name="introduction"
              label="Introduction"
            />
            <CustomFormField
              type="text"
              formik={formik}
              name="Order"
              label="Order"
            />

            <div className="social-media-handles">
              <CustomFormField
                type="text"
                formik={formik}
                name="linkedin"
                label="LinkedIn URL"
              />
              <CustomFormField
                type="text"
                formik={formik}
                name="facebook"
                label="Facebook URL"
              />
              <CustomFormField
                type="text"
                formik={formik}
                name="instagram"
                label="Instagram URL"
              />
              <CustomFormField
                type="text"
                formik={formik}
                name="twitter"
                label="Twitter URL"
              />
            </div>

            <div style={{ marginTop: "1rem", display: "flex", gap: "1rem" }}>
              <Button
                id="uploadBtn"
                variant="contained"
                onClick={() => {
                  setChosenOption("upload");
                  setIsMaleClicked(false);
                  setIsFemaleClicked(false);
                }}
                style={{
                  display: chosenOption === "upload" ? "none" : "block",
                }}
              >
                Upload Image
              </Button>
              <Button
                id="defaultBtn"
                variant="contained"
                onClick={() => {
                  setChosenOption("default");
                }}
                style={{
                  display: chosenOption === "default" ? "none" : "block",
                }}
              >
                Use default Image
              </Button>
            </div>
            {chosenOption === "upload" ? (
              <>
                <InputLabel
                  htmlFor="imageInput"
                  style={{ marginTop: "1.5rem", marginBottom: "-1rem" }}
                >
                  Upload Picture
                </InputLabel>
                <input
                  style={{ marginTop: "25px" }}
                  type="file"
                  id="imageInput"
                  name="image"
                  accept="image/jpeg, image/jpg, image/png"
                  onBlur={formik.handleBlur}
                  onChange={(event) => {
                    formik.setFieldValue(
                      "image",
                      event.currentTarget.files[0] || null
                    );
                  }}
                />
                {formik.touched.image && formik.errors.image ? (
                  <div style={{ color: "red" }}>
                    {formik.errors.image as string}
                  </div>
                ) : formik.values.image ? (
                  <img
                    src={URL.createObjectURL(formik.values.image)}
                    alt="Uploaded Preview"
                    style={{ marginTop: "10px", maxWidth: "100%" }}
                  />
                ) : null}
              </>
            ) : null}
            {chosenOption === "default" ? (
              <>
                <div
                  style={{ display: "flex", gap: "1rem", marginTop: "2rem" }}
                >
                  <div
                    style={{
                      cursor: "pointer",
                      border: `${
                        isMaleClicked
                          ? "2px solid blue"
                          : "2px solid transparent"
                      }`,
                    }}
                    onClick={handleMaleClick}
                  >
                    <p style={{ textAlign: "center", marginBottom: "-1rem" }}>
                      Male
                    </p>
                    <img
                      src="https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/Premed_Avatar_male.png"
                      alt=""
                      style={{
                        width: "250px",
                        height: "auto",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      cursor: "pointer",
                      border: `${
                        isFemaleClicked
                          ? "2px solid blue"
                          : "2px solid transparent"
                      }`,
                    }}
                    onClick={handleFemaleClick}
                  >
                    <p style={{ textAlign: "center", marginBottom: "-1rem" }}>
                      Female
                    </p>
                    <img
                      src="https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/Premed_Avatar_female.png"
                      style={{
                        width: "250px",
                        height: "auto",
                      }}
                      alt=""
                    />
                  </div>
                </div>

                {/* show error */}
                {imageError && (
                  <p
                    style={{
                      color: "red",
                      marginTop: "1rem",
                      fontSize: "1rem",
                      marginLeft: "1rem",
                    }}
                  >
                    {imageError}
                  </p>
                )}
              </>
            ) : null}
          </DialogContent>
        </form>
      </CustomAntdModal>
    </div>
  );
}
