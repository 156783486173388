import React, { useState, useEffect, useCallback } from "react";
import { ButtonFill } from "shared-components/ui/CustomButtons";
import toast, { Toaster } from "react-hot-toast";
import { FlashcardAPI } from "(apis)/(shared-apis)/flashcard-api";
import { SearchBar } from "./components/SearchBar";
import { QuestionTable } from "./components/QuestionTable";
import { QuestionModal } from "./components/QuestionModal";
import { ConfirmationModal } from "shared-components/ui/CustomModal";

const FlashCardQuestionManager = () => {
  const [questions, setQuestions] = useState([]);
  const [loadingStates, setLoadingStates] = useState({
    fetching: false,
    creating: false,
    updating: false,
    deleting: {},
    publishing: {},
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [newQuestion, setNewQuestion] = useState({
    question_text: "",
    answer_text: "",
    extra: [],
  });

  const handleQuestionChange = useCallback((updater) => {
    setNewQuestion((prev) => {
      const next = typeof updater === "function" ? updater(prev) : updater;
      return next;
    });
  }, []);

  const [confirmationModal, setConfirmationModal] = useState({
    isOpen: false,
    action: "",
    questionId: null,
  });

  const [selectedQuestions, setSelectedQuestions] = React.useState<string[]>(
    []
  );

  const handleCheckboxChange = (questionId: string | string[]) => {
    if (Array.isArray(questionId)) {
      setSelectedQuestions(questionId);
    } else {
      setSelectedQuestions((prev) =>
        prev.includes(questionId)
          ? prev.filter((id) => id !== questionId)
          : [...prev, questionId]
      );
    }
  };

  const fetchData = async () => {
    setLoadingStates((prev) => ({ ...prev, fetching: true }));
    try {
      const questionsResponse = await FlashcardAPI("getAllQuestions", {});

      if (questionsResponse?.data?.success) {
        setQuestions(questionsResponse.data.data);
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong";
      toast.error(message);
    } finally {
      setLoadingStates((prev) => ({ ...prev, fetching: false }));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filterQuestions = (questions, searchQuery) => {
    return questions.filter((question) =>
      question.question_text.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const filteredQuestions = filterQuestions(questions, searchQuery);

  const validateQuestion = (question) => {
    // Check if question_text has content (removing HTML tags and whitespace)
    const strippedQuestionText = question.question_text
      ?.replace(/<[^>]*>?/gm, "")
      .trim();
    if (!strippedQuestionText) {
      toast.error("Question text is required");
      return false;
    }

    // Check if answer_text has content (removing HTML tags and whitespace)
    const strippedAnswerText = question.answer_text
      ?.replace(/<[^>]*>?/gm, "")
      .trim();
    if (!strippedAnswerText) {
      toast.error("Answer text is required");
      return false;
    }

    return true;
  };

  const handleCreateQuestion = async (e) => {
    e.preventDefault();
    if (!validateQuestion(newQuestion)) return;
    setLoadingStates((prev) => ({ ...prev, creating: true }));
    try {
      const response = await FlashcardAPI("createQuestion", newQuestion);

      if (response?.data?.success) {
        const newQuestionData = response.data.data;

        // Optimistically update local state
        setQuestions((prevQuestions) => [
          ...prevQuestions,
          { ...newQuestionData, _id: newQuestionData._id },
        ]);

        toast.success("Question created successfully");
        setNewQuestion({
          question_text: "",
          answer_text: "",
          extra: [],
        });
        setIsModalOpen(false);
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong";
      toast.error(message);
    } finally {
      setLoadingStates((prev) => ({ ...prev, creating: false }));
    }
  };

  const handleUpdateQuestion = async (e) => {
    e.preventDefault();
    if (!validateQuestion(newQuestion)) return;
    setLoadingStates((prev) => ({ ...prev, updating: true }));
    try {
      const response = await FlashcardAPI(
        "updateQuestion",
        {
          id: selectedQuestion._id,
          ...newQuestion,
        },
        [selectedQuestion._id]
      );

      if (response?.data?.success) {
        // Optimistically update local state
        setQuestions((prevQuestions) =>
          prevQuestions.map((q) =>
            q._id === selectedQuestion._id ? { ...q, ...newQuestion } : q
          )
        );

        toast.success("Question updated successfully");
        setNewQuestion({
          question_text: "",
          answer_text: "",
          extra: [],
        });
        setIsModalOpen(false);
        setSelectedQuestion(null);
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong";
      toast.error(message);
    } finally {
      setLoadingStates((prev) => ({ ...prev, updating: false }));
    }
  };

  const openConfirmationModal = (action, questionId) => {
    setConfirmationModal({ isOpen: true, action, questionId });
  };

  const handleDeleteConfirmation = async () => {
    if (!confirmationModal.questionId) return;
    const id = confirmationModal.questionId;
    setLoadingStates((prev) => ({
      ...prev,
      deleting: { ...prev.deleting, [id]: true },
    }));
    try {
      const response = await FlashcardAPI("deleteQuestion", {}, [id]);

      if (response?.data?.success) {
        setQuestions((prevQuestions) =>
          prevQuestions.filter((q) => q._id !== id)
        );

        toast.success("Question deleted successfully");
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong";
      toast.error(message);
    } finally {
      setLoadingStates((prev) => ({
        ...prev,
        deleting: { ...prev.deleting, [id]: false },
      }));
      setConfirmationModal({ isOpen: false, action: "", questionId: null });
    }
  };

  const handleDeleteQuestion = (id) => {
    openConfirmationModal("delete this question", id);
  };

  const handlePublishToggle = async (id, currentPublishedStatus) => {
    setLoadingStates((prev) => ({
      ...prev,
      publishing: { ...prev.publishing, [id]: true },
    }));
    try {
      const response = await FlashcardAPI("updateQuestionPublishStatus", {
        id,
        is_published: !currentPublishedStatus,
      });

      if (response?.data?.success) {
        // Optimistically update local state
        setQuestions((prevQuestions) =>
          prevQuestions.map((q) =>
            q._id === id ? { ...q, is_published: !currentPublishedStatus } : q
          )
        );

        toast.success(
          `Question ${
            !currentPublishedStatus ? "published" : "unpublished"
          } successfully`
        );
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong";
      toast.error(message);
    } finally {
      setLoadingStates((prev) => ({
        ...prev,
        publishing: { ...prev.publishing, [id]: false },
      }));
    }
  };

  const openEditModal = (question) => {
    setSelectedQuestion(question);
    setNewQuestion({
      question_text: question.question_text,
      answer_text: question.answer_text,
      extra: question.extra || [],
    });
    setIsModalOpen(true);
  };

  const handleCreateClick = () => {
    setSelectedQuestion(null);
    setNewQuestion({
      question_text: "",
      answer_text: "",
      extra: [],
    });
    setIsModalOpen(true);
  };

  return (
    <div className="w-full pb-20 px-5">
      <Toaster />

      <div className="flex items-center justify-between pb-1 mt-5">
        <div className="flex items-center space-x-4">
          <h2 className="text-2xl font-bold text-gray-700">Question Manager</h2>
          <span className="bg-gray-200 text-gray-800 px-3 py-1 rounded-full text-sm">
            {filteredQuestions.length} Questions
          </span>
        </div>
        <ButtonFill
          handleClick={handleCreateClick}
          disabled={loadingStates.creating}
        >
          {loadingStates.creating ? "Creating..." : "Create Question"}
        </ButtonFill>
      </div>

      <SearchBar searchQuery={searchQuery} onSearchChange={setSearchQuery} />

      {loadingStates.fetching ? (
        <div>Loading questions...</div>
      ) : (
        <QuestionTable
          questions={filteredQuestions}
          onEdit={openEditModal}
          onDelete={handleDeleteQuestion}
          onPublishToggle={handlePublishToggle}
          loadingStates={{
            deleting: loadingStates.deleting,
            publishing: loadingStates.publishing,
          }}
          showCheckboxes={true}
          onCheckboxChange={handleCheckboxChange}
          selectedQuestions={selectedQuestions}
          onSelectAll={setSelectedQuestions}
        />
      )}

      {isModalOpen && (
        <QuestionModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          question={newQuestion}
          onQuestionChange={handleQuestionChange}
          onSave={
            selectedQuestion ? handleUpdateQuestion : handleCreateQuestion
          }
          isLoading={loadingStates.creating || loadingStates.updating}
          isEditing={!!selectedQuestion}
        />
      )}
      {confirmationModal.isOpen && (
        <ConfirmationModal
          active={confirmationModal.isOpen}
          onCancel={() =>
            setConfirmationModal({
              isOpen: false,
              action: "",
              questionId: null,
            })
          }
          onConfirm={handleDeleteConfirmation}
          message={`Are you sure you want to ${confirmationModal.action}?`}
          isIcon={false}
        />
      )}
    </div>
  );
};

export default FlashCardQuestionManager;
