import React from 'react';
import { Input } from 'shared-components/ui/CustomForm';

export const SearchBar = ({ 
  searchQuery, 
  onSearchChange 
}) => {
  return (
    <div className="mb-4">
      <Input
        type="text"
        placeholder="Search questions..."
        value={searchQuery}
        onChange={(_, value) => onSearchChange(value)}
      />
    </div>
  );
};
