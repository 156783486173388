import React, { useState, useEffect } from "react";
import {
  SearchIcon,
  Eye,
  Pencil,
  Trash2,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import CustomDropDown from "shared-components/ui/CustomDropDown";
import Modal from "./modal";
import { apiRequest } from "(apis)/api-interface/api-handler";
import toast from "react-hot-toast";
import { TextbookAPI } from "(apis)/(shared-apis)/textbook-apis";

// Constants for dropdown options
const SUBJECTS = [
  "Physics",
  "Chemistry",
  "Biology",
  "Pak Studies",
  "English",
  "Urdu",
];

const PROVINCES = ["Punjab", "Federal", "KPK", "Sindh", "Balochistan"];

const YEARS = ["1st year", "2nd year"];

const ACCESS_TYPES = ["Free without login", "Free with login", "Paid"];

const ViewTextbooks = () => {
  // State for modal and data management
  const [textbooks, setTextbooks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Replace separate states with a single modal state
  const [modalState, setModalState] = useState<{
    isOpen: boolean;
    textbook: any | null;
  }>({
    isOpen: false,
    textbook: null,
  });

  // Filtering states
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("All");
  const [selectedProvince, setSelectedProvince] = useState("All");
  const [selectedYear, setSelectedYear] = useState("All");
  const [selectedAccessType, setSelectedAccessType] = useState("All");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Fetch textbooks on component mount
  useEffect(() => {
    fetchTextbooks();
  }, []);

  const fetchTextbooks = async () => {
    try {
      setIsLoading(true);
      const response = await TextbookAPI("getTextbooks");

      if (response?.data?.isSuccess) {
        setTextbooks(response?.data?.data);
      } else {
        toast.error("Failed to fetch textbooks");
      }
    } catch (error) {
      console.error("Error fetching textbooks:", error);
      toast.error("An error occurred while fetching textbooks");
    } finally {
      setIsLoading(false);
    }
  };

  // Open modal for adding new textbook
  const openAddModal = () => {
    setModalState({
      isOpen: true,
      textbook: null,
    });
  };

  // Open modal for editing existing textbook
  const openEditModal = (textbook) => {
    setModalState({
      isOpen: true,
      textbook,
    });
  };

  // Close modal
  const closeModal = () => {
    setModalState({
      isOpen: false,
      textbook: null,
    });
  };

  // Delete a textbook
  const handleDelete = async (textbookId) => {
    // Placeholder for delete functionality
    console.log("Delete textbook:", textbookId);
  };

  // Handle view textbook
  const handleViewTextbook = (textbook) => {
    // Open textbook PDF in new tab
    console.log("View textbook:", textbook);
    if (textbook.pdfUrl) {
      window.open(textbook.pdfUrl, "_blank");
    } else {
      toast.error("PDF not available");
    }
  };

  // Filtering logic
  const filteredTextbooks = textbooks?.filter((textbook) => {
    const matchesSearch =
      textbook?.name?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      textbook?.board?.toLowerCase().includes(searchQuery?.toLowerCase());

    const matchesSubject =
      selectedSubject === "All" || textbook?.subject === selectedSubject;

    const matchesProvince =
      selectedProvince === "All" || textbook?.province === selectedProvince;

    const matchesYear =
      selectedYear === "All" || textbook?.year === selectedYear;

    const matchesAccessType =
      selectedAccessType === "All" || textbook?.access === selectedAccessType;

    return (
      matchesSearch &&
      matchesSubject &&
      matchesProvince &&
      matchesYear &&
      matchesAccessType
    );
  });

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredTextbooks?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil((filteredTextbooks?.length || 0) / itemsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Reset to first page when filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [
    searchQuery,
    selectedSubject,
    selectedProvince,
    selectedYear,
    selectedAccessType,
  ]);

  return (
    <div className="w-full pb-20 px-5">
      {/* Modal for Add/Edit Textbook */}
      <Modal
        isModalOpen={modalState.isOpen}
        closeModal={closeModal}
        initialData={modalState.textbook}
        // onSubmit={handleSubmit}
      />

      <section className="my-5 antialiased">
        <div className="mx-auto">
          {isLoading ? (
            <div className="text-center py-10">Loading...</div>
          ) : (
            <div className="sm:rounded-lg">
              {/* Search Section */}
              <div className="flex items-center justify-between p-4">
                <div className="relative flex-grow max-w-2xl">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <SearchIcon color="text-gray-400" />
                  </div>
                  <input
                    type="text"
                    className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-none outline-none"
                    placeholder="Search by Name or Board"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
                <div className="flex items-center gap-3">
                  <div className="text-sm">
                    <span className="font-medium text-gray-700">
                      {filteredTextbooks?.length}
                    </span>
                    <span className="text-gray-500">
                      {filteredTextbooks?.length === 1
                        ? " textbook"
                        : " textbooks"}
                    </span>
                  </div>
                </div>
              </div>

              {/* Filtering Dropdowns */}
              <div className="flex items-end p-4 space-x-3">
                <CustomDropDown
                  label="Subject"
                  options={["All", ...SUBJECTS]}
                  onSelect={(value) => setSelectedSubject(value.toString())}
                  width="min-w-[12rem]"
                  value={selectedSubject}
                />
                <CustomDropDown
                  label="Province"
                  options={["All", ...PROVINCES]}
                  onSelect={(value) => setSelectedProvince(value.toString())}
                  width="min-w-[12rem]"
                  value={selectedProvince}
                />
                <CustomDropDown
                  label="Year"
                  options={["All", ...YEARS]}
                  onSelect={(value) => setSelectedYear(value.toString())}
                  width="min-w-[12rem]"
                  value={selectedYear}
                />
                <CustomDropDown
                  label="Access Type"
                  options={["All", ...ACCESS_TYPES]}
                  onSelect={(value) => setSelectedAccessType(value.toString())}
                  width="min-w-[12rem]"
                  value={selectedAccessType}
                />
              </div>

              {/* Textbooks Table */}
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th scope="col" className="px-4 py-4">
                      Name
                    </th>
                    <th scope="col" className="px-4 py-4">
                      Board
                    </th>
                    <th scope="col" className="px-4 py-4">
                      Subject
                    </th>
                    <th scope="col" className="px-4 py-4">
                      Province
                    </th>
                    <th scope="col" className="px-4 py-4">
                      Year
                    </th>
                    <th scope="col" className="px-4 py-4">
                      Access Type
                    </th>
                    <th scope="col" className="px-4 py-4">
                      Marketing
                    </th>
                    <th scope="col" className="px-4 py-4">
                      Status
                    </th>
                    <th scope="col" className="px-4 py-4">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.map((textbook) => (
                    <tr
                      key={textbook?._id}
                      className="border-b hover:bg-gray-50"
                    >
                      <td className="px-4 py-3">{textbook?.name}</td>
                      <td className="px-4 py-3">{textbook?.board}</td>
                      <td className="px-4 py-3">{textbook?.subject}</td>
                      <td className="px-4 py-3">{textbook?.province}</td>
                      <td className="px-4 py-3">{textbook?.year}</td>
                      <td className="px-4 py-3">{textbook?.access}</td>
                      <td className="px-4 py-3">
                        <span
                          className={`px-2 py-1 text-xs rounded-full ${
                            textbook?.isFreeForMarketing
                              ? "bg-green-100 text-green-800"
                              : "bg-gray-100 text-gray-800"
                          }`}
                        >
                          {textbook?.isFreeForMarketing ? "Free" : "Paid"}
                        </span>
                      </td>
                      <td className="px-4 py-3">
                        <span
                          className={`px-2 py-1 text-xs rounded-full ${
                            textbook?.isPublished
                              ? "bg-green-100 text-green-800"
                              : "bg-yellow-100 text-yellow-800"
                          }`}
                        >
                          {textbook?.isPublished ? "Published" : "Draft"}
                        </span>
                      </td>
                      <td className="px-4 py-3">
                        <div className="flex items-center gap-2">
                          {/* View Button */}
                          {textbook?.pdfUrl && textbook?.pdfUrl !== "" && (
                            <button
                              onClick={() => handleViewTextbook(textbook)}
                              className="p-1.5 text-gray-500 hover:text-blue-600 hover:bg-blue-50 rounded-lg transition-colors duration-200"
                              title="View PDF"
                            >
                              <Eye size={18} />
                            </button>
                          )}

                          {/* Edit Button */}
                          <button
                            onClick={() => openEditModal(textbook)}
                            className="p-1.5 text-gray-500 hover:text-blue-600 hover:bg-blue-50 rounded-lg transition-colors duration-200"
                            title="Edit"
                          >
                            <Pencil size={18} />
                          </button>

                          {/* Delete Button */}
                          <button
                            onClick={() => handleDelete(textbook._id)}
                            className="p-1.5 text-gray-500 hover:text-red-600 hover:bg-red-50 rounded-lg transition-colors duration-200"
                            title="Delete"
                          >
                            <Trash2 size={18} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* No Results Message */}
              {filteredTextbooks?.length === 0 && (
                <div className="text-center py-10 text-gray-500">
                  No textbooks found
                </div>
              )}

              {/* Pagination */}
              {filteredTextbooks?.length > 0 && (
                <div className="flex items-center justify-between px-4 py-3 bg-white border-t">
                  <div className="flex items-center text-sm text-gray-500">
                    <span>
                      Showing{" "}
                      <span className="font-medium text-gray-700">
                        {indexOfFirstItem + 1}
                      </span>{" "}
                      to{" "}
                      <span className="font-medium text-gray-700">
                        {Math.min(indexOfLastItem, filteredTextbooks.length)}
                      </span>{" "}
                      of{" "}
                      <span className="font-medium text-gray-700">
                        {filteredTextbooks.length}
                      </span>{" "}
                      results
                    </span>
                  </div>

                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => paginate(currentPage - 1)}
                      disabled={currentPage === 1}
                      className={`p-2 rounded-lg ${
                        currentPage === 1
                          ? "text-gray-300 cursor-not-allowed"
                          : "text-gray-500 hover:bg-gray-100"
                      }`}
                      aria-label="Previous page"
                    >
                      <ChevronLeft size={20} />
                    </button>

                    {/* Page numbers */}
                    <div className="flex items-center space-x-1">
                      {[...Array(totalPages)].map((_, index) => {
                        const pageNumber = index + 1;
                        // Show first page, last page, current page, and one page before and after current
                        if (
                          pageNumber === 1 ||
                          pageNumber === totalPages ||
                          (pageNumber >= currentPage - 1 &&
                            pageNumber <= currentPage + 1)
                        ) {
                          return (
                            <button
                              key={pageNumber}
                              onClick={() => paginate(pageNumber)}
                              className={`px-3 py-1 rounded-lg ${
                                currentPage === pageNumber
                                  ? "bg-blue-50 text-blue-600 font-medium"
                                  : "text-gray-500 hover:bg-gray-100"
                              }`}
                            >
                              {pageNumber}
                            </button>
                          );
                        } else if (
                          pageNumber === currentPage - 2 ||
                          pageNumber === currentPage + 2
                        ) {
                          return <span key={pageNumber}>...</span>;
                        }
                        return null;
                      })}
                    </div>

                    <button
                      onClick={() => paginate(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      className={`p-2 rounded-lg ${
                        currentPage === totalPages
                          ? "text-gray-300 cursor-not-allowed"
                          : "text-gray-500 hover:bg-gray-100"
                      }`}
                      aria-label="Next page"
                    >
                      <ChevronRight size={20} />
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default ViewTextbooks;
