import { useState } from "react";
import {
  ChevronDown,
  ChevronUp,
  EditIcon,
  ExternalLinkIcon,
  PreviewIcon,
} from "../../../shared-components/ui/Icons";
import CommissionUserForm from "./CommissionUserForm";
import CustomBadges from "../../../shared-components/ui/CustomBadges";
import toast, { Toaster } from "react-hot-toast";
import {
  renderCellColor,
  renderChips,
  getTotalAmountEarned,
  ROlES,
  REIMBURSEMENT_PAGE_ACCESS_EMAILS,
} from "../utils";

import { CommissionAPI } from "../../../(apis)/(shared-apis)/latest-commission-api";
import { ConfirmationModal } from "shared-components/ui/CustomModal";
import CustomDropDown from "shared-components/ui/CustomDropDown";

export const ViewCommissionUser = ({
  allUsers,
  setAllUsers,
  adminUsers,
  nestedStructure,
  reportToUsers,
  allAdminUsers,
  allCommissionUsers,
  label,
}) => {
  // console.log("reportToUsers: ", reportToUsers);
  // console.log("allUsers: ", allUsers, " adminUsers: ", adminUsers);
  const [openDisciplineFormModal, setDisciplineModal] = useState(false);
  const [toBeViewCommissionUser, setToBeViewDiscipline] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedUserForStatusChange, setSelectedUserForStatusChange] =
    useState(null);
  const [loadingUsers, setLoadingUsers] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [userStatusFilter, setUserStatusFilter] = useState("Active Users");
  const PerPage = 10;

  // Filtered users based on status
  const filteredUsers = allUsers.filter((user) => {
    if (userStatusFilter === "Active Users") {
      return user.isActive === true;
    } else if (userStatusFilter === "Inactive Users") {
      return user.isActive === false;
    }
    return true;
  });

  // Calculate active and inactive user counts
  const activeUserCount = allUsers.filter((user) => user.isActive).length;
  const inactiveUserCount = allUsers.filter((user) => !user.isActive).length;

  const UpdateUserStatus = async () => {
    if (!selectedUserForStatusChange) return;

    try {
      // Set loading state for this specific user
      setLoadingUsers((prev) => ({
        ...prev,
        [selectedUserForStatusChange._id]: true,
      }));

      // Call API to update user status
      const response = await CommissionAPI("updateUserStatus", [], {
        _id: selectedUserForStatusChange._id,
        isActive: !selectedUserForStatusChange.isActive,
      });

      if (response?.data?.success) {
        // Update local state
        setAllUsers((prevUsers) =>
          prevUsers.map((user) =>
            user._id === selectedUserForStatusChange._id
              ? { ...user, isActive: !user.isActive }
              : user
          )
        );

        // Show success toast or message
        toast.success(
          `User ${selectedUserForStatusChange.name} ${
            selectedUserForStatusChange.isActive ? "deactivated" : "activated"
          } successfully`
        );
      } else {
        // Show error toast
        toast.error(response?.data?.message || "Failed to update user status");
      }
    } catch (error) {
      console.error("Error updating user status:", error);
      toast.error("Failed to update user status");
    } finally {
      // Remove loading state
      setLoadingUsers((prev) => {
        const newLoadingUsers = { ...prev };
        delete newLoadingUsers[selectedUserForStatusChange._id];
        return newLoadingUsers;
      });

      // Close confirmation modal
      setConfirmationModal(false);
      setSelectedUserForStatusChange(null);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getTeamLength = (user, role) => {
    const updateFields = {
      NATIONAL_DIRECTOR: "provincialDirectorIds",
      PROVINCIAL_DIRECTOR: "managerIds",
      DIRECTOR: "managerIds",
      MANAGER: "associateIds",
      HEAD: "associateIds",
      ASSOCIATE: null,
    };

    const fieldName = updateFields[role];

    if (fieldName && user[fieldName]) {
      // Filter out only active users
      const activeTeamMembers = user[fieldName].filter(
        (member) =>
          member.isActive === true ||
          (typeof member === "object" && member.isActive === true)
      );

      return activeTeamMembers.length;
    }

    return 0;
  };

  return (
    <>
      {/* User Count Cards */}
      <div className="flex items-center gap-4 px-4 mb-4">
        {/* Active Users Card */}
        <div className="bg-green-50 min-w-fit gap-x-10 border border-green-200 rounded-lg p-4 flex items-center justify-between">
          <div>
            <h3 className="text-lg font-semibold text-green-800">
              Active{" "}
              <span className="capitalize">
                {" "}
                {label ? label?.toLowerCase() + "s" : "users"}
              </span>
            </h3>
            <p className="text-sm text-green-600">
              Total number of active{" "}
              {label ? label?.toLowerCase() + "s" : "users"}
            </p>
          </div>
          <div className="text-3xl font-bold text-green-700">
            {activeUserCount}
          </div>
        </div>

        {/* Inactive Users Card */}
        <div className="bg-red-50 min-w-fit gap-x-10 border border-red-200 rounded-lg p-4 flex items-center justify-between">
          <div>
            <h3 className="text-lg font-semibold text-red-800">
              Inactive{" "}
              <span className="capitalize">
                {" "}
                {label ? label?.toLowerCase() + "s" : "users"}
              </span>
            </h3>
            <p className="text-sm text-red-600">
              Total number of inactive{" "}
              {label ? label?.toLowerCase() + "s" : "users"}
            </p>
          </div>
          <div className="text-3xl font-bold text-red-700">
            {inactiveUserCount}
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between mb-4 px-4">
        <div className="flex items-center space-x-2">
          <CustomDropDown
            label="Filter by Status:"
            options={["Active Users", "Inactive Users", "All Users"]}
            onSelect={(e) => setUserStatusFilter(e)}
            width="w-full"
            value={userStatusFilter}
            isError={false}
            errorMessage={""}
          />
        </div>
      </div>

      <div className="overflow-x-auto">
        <Toaster />

        <table className="w-full mb-20 text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-4">
                Name
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Email
              </th>
              {/* <th scope="col" className="px-4 py-4 text-left">
                Role
              </th> */}
              <th scope="col" className="px-4 py-4 ">
                Report Info
              </th>
              {/* <th scope="col" className="px-4 py-4 text-left">
                Report to
              </th> */}
              {/* {label !== ROlES.ASSOCIATE && (
                <th scope="col" className="px-4 py-4 text-left">
                  Team
                </th>
              )} */}

              <th scope="col" className="px-4 py-4 text-center">
                Active
              </th>
              <th scope="col" className="px-4 py-4 text-center">
                Commision%
              </th>
              <th scope="col" className="px-4 py-4 text-center">
                Amount Earned
              </th>
              <th scope="col" className="px-4 py-3 text-center">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers
              // ?.slice((currentPage - 1) * PerPage, currentPage * PerPage)
              ?.map((user) => (
                <tr className="border-b" key={user?._id}>
                  <td className="px-4 py-3">{user?.name}</td>
                  <td className="px-4 py-3">{user?.email}</td>
                  {/* <td className="px-4 py-3 text-left">
                    {renderChips(user?.role)}
                  </td> */}

                  <td className="px-4 py-3">
                    {user?.reportTo?.email}{" "}
                    {user?.reportTo?.name ? "- " + user?.reportTo?.name : ""}
                  </td>
                  {/* <td className="px-4 py-3 text-left">
                    {renderChips(user?.reportTo?.role)}
                  </td> */}

                  {/* {label !== ROlES.ASSOCIATE && (
                    <td className="px-4 py-3 text-left">
                      {getTeamLength(user, user?.role)}
                    </td>
                  )} */}

                  <td className="px-4 py-3">
                    <div className="mx-auto w-fit">
                      <button
                        onClick={() => {
                          if (
                            getTeamLength(user, user?.role) !== 0 &&
                            user?.isActive
                          ) {
                            toast.error(
                              `Cannot change status. ${
                                user?.name
                              } still manages ${getTeamLength(
                                user,
                                user?.role
                              )} team member(s) who report directly to them. Please reassign these team members before changing their status.`
                            );
                            return;
                          }
                          setSelectedUserForStatusChange(user);
                          setConfirmationModal(true);
                        }}
                        className={`px-2 py-1 rounded-full text-xs font-medium ${
                          !user?.isActive
                            ? "bg-red-100 text-red-800 hover:bg-red-200"
                            : "bg-green-100 text-green-800 hover:bg-green-200"
                        }`}
                        disabled={loadingUsers[user?._id]}
                      >
                        {loadingUsers[user?._id]
                          ? "Updating..."
                          : user?.isActive
                          ? "Activate"
                          : "Deactivate"}
                      </button>
                    </div>
                  </td>
                  <td className="px-4 py-3 text-center">
                    <CustomBadges
                      type="warning"
                      label={user?.commissionPercentage}
                      showIcon={false}
                    />
                  </td>
                  <td className="px-4 py-3 text-center">
                    {getTotalAmountEarned(user)}
                  </td>
                  <td className="relative flex items-center justify-center px-4 py-3">
                    <div className="flex items-center space-x-2">
                      <button
                        type="button"
                        onClick={() => {
                          window.open(
                            `/manage/commission/view/${user?._id}`,
                            "_blank"
                          );
                        }}
                      >
                        <ExternalLinkIcon />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>

          <CommissionUserForm
            mode="edit"
            active={openDisciplineFormModal}
            setModal={setDisciplineModal}
            onCancel={() => {
              setDisciplineModal(false);
            }}
            onConfirm={() => {
              setDisciplineModal(false);
            }}
            commissionUser={toBeViewCommissionUser}
            setDiscipline={setAllUsers}
            allUsers={allUsers}
            adminUsers={adminUsers}
            reportToUsers={filteredUsers}
            label={label}
          />
        </table>

        {label !== ROlES.ASSOCIATE && (
          <UserCard2
            getTeamLength={getTeamLength}
            renderChips={renderChips}
            setDisciplineModal={setDisciplineModal}
            setToBeViewDiscipline={setToBeViewDiscipline}
            users={nestedStructure}
            level={0}
          />
        )}
      </div>
      <nav
        className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
        aria-label="Table navigation"
      >
        <span className="text-sm font-normal text-gray-500 space-x-2">
          <span> Showing</span>
          <span className="font-semibold text-gray-900 ">
            {currentPage * PerPage - PerPage + 1}
          </span>
          <span> of</span>
          <span className="font-semibold text-gray-900">
            {filteredUsers.length}
          </span>
        </span>
        <ul className="inline-flex items-stretch -space-x-px">
          <li>
            <button
              className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover-text-gray-700"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <span className="sr-only">Previous</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </li>
          {Array.from({
            length: Math.ceil(filteredUsers.length / PerPage),
          }).map((_, index) => (
            <li key={index}>
              <button
                className={`flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover-bg-gray-100 ${
                  currentPage === index + 1
                    ? "font-semibold text-primary-600 bg-primary-50"
                    : ""
                }`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            </li>
          ))}
          <li>
            <button
              className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover-text-gray-700"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <span className="sr-only">Next</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </li>
        </ul>
      </nav>

      <ConfirmationModal
        active={confirmationModal}
        onCancel={() => {
          setConfirmationModal(false);
        }}
        onConfirm={() => {
          UpdateUserStatus();
        }}
        message={`Are you sure you want to ${
          selectedUserForStatusChange?.isActive ? "deactivate" : "activate"
        } user ${selectedUserForStatusChange?.name}?`}
        loading={loadingUsers[selectedUserForStatusChange?._id]}
      />
    </>
  );
};

const getUserIdField = (role) => {
  switch (role) {
    case "NATIONAL_DIRECTOR":
      return "provincialDirectorIds";
    case "PROVINCIAL_DIRECTOR":
      return "managerIds";
    case "DIRECTOR":
      return "managerIds";
    case "MANAGER":
      return "associateIds";
    case "HEAD":
      return "associateIds";
    default:
      return null;
  }
};

const UserCard2 = ({
  users,
  renderChips,
  getTeamLength,
  setDisciplineModal,
  setToBeViewDiscipline,
  level = 0,
}) => {
  const [expandedUsers, setExpandedUsers] = useState({});

  const toggleExpand = (userId) => {
    setExpandedUsers((prev) => ({
      ...prev,
      [userId]: !prev[userId],
    }));
  };

  return (
    <div className="w-full">
      {/* Header */}
      {/* {level === 0 && ( */}
      <div
        className={`grid grid-cols-12 gap-2  p-2 rounded text-xs font-semibold  mb-1 ${renderCellColor(
          level === 0
            ? ROlES.DIRECTOR
            : level === 1
            ? ROlES.MANAGER
            : ROlES.ASSOCIATE
        )} 
        `}
      >
        {/* <div></div> Extra column for chevron */}
        <div className="col-span-2">Name</div>
        <div className="col-span-2">Email</div>
        <div>Role</div>
        <div className="col-span-2">ReportInfo</div>
        <div>ReportTo</div>
        <div>Team</div>
        <div className="text-center">Com %</div>
        <div className="text-center">Earned</div>
        <div className="text-center">Actions</div>
      </div>
      {/* )} */}

      {/* User Data Rows */}
      {users &&
        users
          ?.filter((user) => user?.isActive)
          .map((user: any) => {
            // const fieldName = user?.isPromoted
            //   ? getUserIdField(user?.promotedFrom)
            //   : getUserIdField(user?.role);

            const fieldName = getUserIdField(user?.role);
            // ? getUserIdField(user?.promotedFrom)
            // : getUserIdField(user?.role);

            if (
              user?.role === ROlES.DIRECTOR &&
              user?.email === "dawsooonpark@gmail.com"
            ) {
              return null;
            }

            return (
              <div
                key={user?._id}
                className={`${!user?.isActive && "bg-red-100"}`}
                style={{ marginLeft: `${level * 0.2}%` }}
              >
                <div className="grid grid-cols-12 gap-2 p-2 border-b text-xs text-gray-700 items-center">
                  {/* Chevron Icon Column */}
                  <div
                    className="flex items-center justify-start col-span-2 space-x-1"
                    onClick={() => toggleExpand(user?._id)}
                  >
                    {user[fieldName] && user[fieldName]?.length > 0 ? (
                      <button>
                        {expandedUsers[user?._id] ? (
                          <ChevronUp size="w-3 h-3 " />
                        ) : (
                          <ChevronDown size="w-3 h-3" />
                        )}
                      </button>
                    ) : (
                      "-- "
                    )}
                    <div className="text-xs ">{user?.name}</div>
                  </div>

                  <div className="text-xs col-span-2">{user?.email}</div>
                  <div className="text-xs">
                    {renderChips(
                      user?.role && user?.role?.trim() !== ""
                        ? user?.role
                        : "-",
                      "xs",
                      true
                    )}
                  </div>
                  <div className="text-xs col-span-2">
                    {user?.reportTo?.email}{" "}
                    {user?.reportTo?.name ? "- " + user?.reportTo?.name : ""}
                  </div>
                  <div className="text-xs">
                    {renderChips(
                      user?.role && user?.role?.trim() !== ""
                        ? user?.role
                        : "-",
                      "xs",
                      true
                    )}
                  </div>
                  <div className="text-xs">
                    {getTeamLength(user, user?.role)}
                  </div>
                  <div className="text-center text-xs">
                    <CustomBadges
                      type="warning"
                      label={user?.commissionPercentage}
                      showIcon={false}
                    />
                  </div>
                  <div className="text-center text-xs">
                    {getTotalAmountEarned(user)}
                  </div>
                  <div className="text-center text-xs">
                    <button
                      type="button"
                      onClick={() => {
                        window.open(
                          `/manage/commission/view/${user?._id}`,
                          "_blank"
                        );
                      }}
                    >
                      <ExternalLinkIcon />
                    </button>
                  </div>
                </div>

                {/* Recursively render nested users if expanded */}
                {expandedUsers[user?._id] &&
                  user[fieldName] &&
                  user[fieldName]?.length > 0 && (
                    <UserCard2
                      getTeamLength={getTeamLength}
                      renderChips={renderChips}
                      setDisciplineModal={setDisciplineModal}
                      setToBeViewDiscipline={setToBeViewDiscipline}
                      users={user[fieldName]}
                      level={level + 1}
                    />
                  )}
              </div>
            );
          })}
    </div>
  );
};

export default ViewCommissionUser;
