import axios from "axios";
import { backendUrl } from "../../constants/config";
import { ApiInterface } from "interfaces/global-interfaces";

export const textbookApis: ApiInterface[] = [
  {
    url: "/api/textbook/get",
    method: "GET",
    name: "getTextbooks",
  },
  {
    url: "/api/textbook/publish",
    method: "PUT",
    name: "updateTextbookPublishedStatusById",
  },
  {
    url: "/api/textbook/add",
    method: "POST",
    name: "addTextbook",
  },
  {
    url: "/api/textbook/update",
    method: "PUT",
    name: "updateTextbook",
  },
  {
    url: "/api/textbook",
    method: "DELETE",
    name: "deleteTextbook",
  },
];

export function TextbookAPI(
  name: string,
  params: string[] = [""],
  data: any = null
) {
  const api = textbookApis.find((api) => api.name === name);
  if (!api) {
    throw new Error("API not found");
  }

  const url = backendUrl + api.url + (params ? "/" + params.join("/") : "");

  return axios({
    method: api.method,
    data,
    withCredentials: true,
    url,
  });
}
