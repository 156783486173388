const GetPlanNames = (student: any) => {
    let planNames = [];
    const features = student.featuresPurchased || [];
    for (const feature of features) {
        planNames.push(feature.planName);
    }
    return planNames;
}

const GetOwnCourses = (student: any) => {
    let courseNames = [];
    const courses = student.courses_bundles || [];
    for (const course of courses) {
        courseNames.push(course.course_name);
    }
    return courseNames;
}

export { GetPlanNames, GetOwnCourses };