import React, { useState, useEffect, useCallback } from "react";
import { ButtonFill, ButtonOutlined } from "shared-components/ui/CustomButtons";
import toast, { Toaster } from "react-hot-toast";
import { Modal } from "antd";
import { FlashcardAPI } from "(apis)/(shared-apis)/flashcard-api";
import { useParams } from "react-router-dom";
import { Edit, Eye } from "lucide-react";
import { FileUpload } from "shared-components/ui/file-upload";
import { QuestionModal } from "../flashcards_questions/components/QuestionModal";
import { ViewQuestionModal } from "../flashcards_questions/components/ViewQuestionModal";
import { Tooltip } from "antd";

const SubjectManager = () => {
  const { id } = useParams();
  const [subject, setSubject] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [tagInput, setTagInput] = useState("");
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [viewingQuestion, setViewingQuestion] = useState(null);

  const [newQuestion, setNewQuestion] = useState({
    question_text: "",
    answer_text: "",
    extra: "",
    description: "",
    reference: "",
    tags: [],
    image: null,
    difficulty: 1, // default value as number
  });

  const [loadingStates, setLoadingStates] = useState({
    creating: false,
    updating: false,
  });

  const handleQuestionChange = useCallback((updater) => {
    setNewQuestion((prev) => {
      const next = typeof updater === "function" ? updater(prev) : updater;
      return next;
    });
  }, []);

  const fetchSubjectById = async () => {
    setIsLoading(true);
    try {
      const response = await FlashcardAPI("getSubjectById", null, [id]);
      if (response?.data?.success) {
        setSubject(response.data.data);
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong";
      toast.error(message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSubjectById();
  }, []);

  const validateQuestion = (question) => {
    const strippedQuestionText = question.question_text
      ?.replace(/<[^>]*>?/gm, "")
      .trim();
    if (!strippedQuestionText) {
      toast.error("Question text is required");
      return false;
    }

    const strippedAnswerText = question.answer_text
      ?.replace(/<[^>]*>?/gm, "")
      .trim();
    if (!strippedAnswerText) {
      toast.error("Answer text is required");
      return false;
    }

    return true;
  };

  const handleCreateQuestion = async () => {
    if (!validateQuestion(newQuestion)) return;
    setLoadingStates((prev) => ({ ...prev, creating: true }));
    try {
      const formData = new FormData();

      // Add all text fields
      formData.append("question_text", newQuestion.question_text);
      formData.append("answer_text", newQuestion.answer_text);
      formData.append("description", newQuestion.description || "");
      formData.append("reference", newQuestion.reference || "");
      formData.append("extra", newQuestion.extra || "");
      formData.append("difficulty", String(newQuestion.difficulty || 1));

      // Add tags as JSON string
      formData.append("tags", JSON.stringify(newQuestion.tags || []));

      // Handle image
      if (newQuestion.image) {
        // Case 1: If image is a File object
        if (newQuestion.image instanceof File) {
          formData.append("image", newQuestion.image);
        }
        // Case 2: If image is a blob URL
        else if (
          typeof newQuestion.image === "string" &&
          newQuestion.image.startsWith("blob:")
        ) {
          const imageBlob = await fetch(newQuestion.image).then((r) =>
            r.blob()
          );
          const imageFile = new File([imageBlob], "question_image", {
            type: imageBlob.type,
          });
          formData.append("image", imageFile);
        }
      }

      const response = await FlashcardAPI("createQuestion", formData, [id]);

      if (response?.data?.success) {
        toast.success("Question created successfully");
        setNewQuestion({
          question_text: "",
          answer_text: "",
          extra: "",
          description: "",
          reference: "",
          tags: [],
          image: null,
          difficulty: 1,
        });
        setIsModalOpen(false);
        fetchSubjectById();
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong";
      toast.error(message);
    } finally {
      setLoadingStates((prev) => ({ ...prev, creating: false }));
    }
  };

  const handleUpdateQuestion = async () => {
    if (!validateQuestion(newQuestion)) return;
    setLoadingStates((prev) => ({ ...prev, updating: true }));
    try {
      const formData = new FormData();

      // Add all text fields
      formData.append("question_text", newQuestion.question_text);
      formData.append("answer_text", newQuestion.answer_text);
      formData.append("description", newQuestion.description || "");
      formData.append("reference", newQuestion.reference || "");
      formData.append("extra", newQuestion.extra || "");
      formData.append("difficulty", String(newQuestion.difficulty || 1));
      formData.append("tags", JSON.stringify(newQuestion.tags || []));

      // Handle image:
      // If it's a File or Blob, send as new image
      if (
        newQuestion.image instanceof File ||
        newQuestion.image instanceof Blob
      ) {
        formData.append("image", newQuestion.image);
      }
      // Otherwise send the image URL (empty string to remove image)
      else {
        formData.append("imageUrl", newQuestion.image || "");
      }

      const response = await FlashcardAPI("updateQuestion", formData, [
        selectedQuestion._id,
      ]);

      if (response?.data?.success) {
        toast.success("Question updated successfully");
        setNewQuestion({
          question_text: "",
          answer_text: "",
          extra: "",
          description: "",
          reference: "",
          tags: [],
          image: null,
          difficulty: 1,
        });
        setIsModalOpen(false);
        setSelectedQuestion(null);
        fetchSubjectById();
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong";
      toast.error(message);
    } finally {
      setLoadingStates((prev) => ({ ...prev, updating: false }));
    }
  };

  const truncateHtml = (html: string, maxLength = 100) => {
    if (!html) return "";
    // Temporarily replace closing tags with a special marker
    const withMarkers = html.replace(/<\//g, "§§");
    // Remove all HTML tags
    const textOnly = withMarkers.replace(/<[^>]*>/g, "");
    // Restore closing tags
    const withClosingTags = textOnly.replace(/§§/g, "</");
    // Trim and truncate
    if (withClosingTags.length <= maxLength) return withClosingTags;
    return withClosingTags.substring(0, maxLength) + "...";
  };

  return (
    <div className="w-full pb-20 px-5">
      <Toaster />

      <div className="flex items-center justify-between pb-1 mt-5">
        <h2 className="text-2xl font-bold text-gray-700">Subject Manager</h2>
        <ButtonFill
          handleClick={() => {
            setSelectedQuestion(null);
            setNewQuestion({
              question_text: "",
              answer_text: "",
              extra: "",
              description: "",
              reference: "",
              tags: [],
              image: null,
              difficulty: 1,
            });
            setIsModalOpen(true);
          }}
        >
          Create Question
        </ButtonFill>
      </div>

      <div className="overflow-x-auto mt-10">
        <table className="w-full mb-20 text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-4">
                Question Text
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Answer Text
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Description
              </th>
              {/* <th scope="col" className="px-4 py-4 text-left">
                Tags
              </th> */}
              <th scope="col" className="px-4 py-3 text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {subject?.questions.map((question) => (
              <tr className="border-b text-gray-800" key={question._id}>
                <td className="px-4 py-3">
                  <Tooltip
                    title={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: question.question_text,
                        }}
                      />
                    }
                  >
                    <div
                      className="cursor-help"
                      dangerouslySetInnerHTML={{
                        __html: truncateHtml(question.question_text),
                      }}
                    />
                  </Tooltip>
                </td>
                <td className="px-4 py-3">
                  <Tooltip
                    title={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: question.answer_text,
                        }}
                      />
                    }
                  >
                    <div
                      className="cursor-help"
                      dangerouslySetInnerHTML={{
                        __html: truncateHtml(question.answer_text),
                      }}
                    />
                  </Tooltip>
                </td>
                <td className="px-4 py-3">
                  <Tooltip title={question.description || "N/A"}>
                    <div className="cursor-help">
                      {truncateHtml(question.description || "", 50)}
                    </div>
                  </Tooltip>
                </td>
                {/* <td className="px-4 py-3">
                  <div className="flex flex-wrap gap-1">
                    {question.tags?.map((tag, index) => (
                      <span
                        key={index}
                        className="bg-gray-100 px-2 py-1 rounded-full text-xs"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                </td> */}
                <td className="px-4 py-3">
                  <div className="flex items-center gap-2">
                    <Eye
                      className="w-6 h-6 text-gray-500 cursor-pointer"
                      onClick={() => {
                        setViewingQuestion(question);
                        setIsViewModalOpen(true);
                      }}
                    />
                    <Edit
                      className="w-6 h-6 text-gray-500 cursor-pointer"
                      onClick={() => {
                        const questionToEdit = {
                          ...question,
                          // Keep the image URL if it exists
                          image: question.image || null,
                          // Ensure tags is an array
                          tags: question.tags || [],
                          // Ensure difficulty is a number
                          difficulty: Number(question.difficulty) || 1,
                          // Ensure other fields have defaults
                          description: question.description || "",
                          reference: question.reference || "",
                          extra: question.extra || "",
                        };
                        setSelectedQuestion(question);
                        setNewQuestion(questionToEdit);
                        setIsModalOpen(true);
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <QuestionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        question={newQuestion}
        onQuestionChange={handleQuestionChange}
        onSave={selectedQuestion ? handleUpdateQuestion : handleCreateQuestion}
        isLoading={loadingStates.creating || loadingStates.updating}
        isEditing={!!selectedQuestion}
      />

      <ViewQuestionModal
        isOpen={isViewModalOpen}
        onClose={() => {
          setIsViewModalOpen(false);
          setViewingQuestion(null);
        }}
        question={viewingQuestion}
      />
    </div>
  );
};

export default SubjectManager;
