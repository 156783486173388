
import { ButtonFill } from "../../../shared-components/ui/CustomButtons";
import { toast } from 'react-hot-toast';


const ConversionSection = ({ filteredUsers, FormatAccountCreatedDate, setSelectedTrigger, selectedTrigger, users, triggers, showFreeUsers, setShowFreeUsers }) => {
    const DownloadCSV = () => {
        const csv = filteredUsers.map((user: any) => {
            return {
                "Full Name": user.fullname,
                "Username": user.username,
                "Phone Number": user.phonenumber,
                "On WhatsApp": user?.info?.isAvailableOnWhatsApp,
                "Features Purchased": user.featuresPurchased?.map((feature:any) => feature.planName).join(', '),
                "City": user.city,
                "Education": user?.info?.educationSystem
                    ? user.info.educationSystem
                    : (user?.info?.exam
                        ? user.info.exam.map((ex) => ex).join(', ')
                        : 'No information available'),
                "Account Created": FormatAccountCreatedDate(user.accountcreateddate),
            };
        });

        if (!csv.length) {
            toast.error('No data to download');
            return;
        }

        const headers = Object.keys(csv[0]).join(',');
        const rows = csv.map((row: any) => Object.values(row).join(',')).join('\n');
        const csvData = `${headers}\n${rows}`;

        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'conversions.csv';
        a.click();
    }

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
                {triggers.map((item: any, index: number) => (
                    <div key={index} className={
                        `${selectedTrigger === item.triggerName ? 'bg-primary text-white' : 'bg-white'}
                                          shadow sm:rounded border border-gray-200 p-4 cursor-pointer`} onClick={() => setSelectedTrigger(item.triggerName)}>
                        <h1 className='text-lg font-bold'>{item.triggerName}</h1>
                        <p className='text-sm'>Total: {item.value}</p>
                    </div>
                ))}

                <div className={
                    `${selectedTrigger === "All" ? 'bg-primary text-white' : 'bg-white'}
                                          shadow sm:rounded border border-gray-200 p-4 cursor-pointer`} onClick={() => setSelectedTrigger('All')}>
                    <h1 className='text-lg font-bold'>All</h1>
                    <p className='text-sm'>Total: {users.length}</p>
                </div>

                <div className={`${showFreeUsers ? 'bg-primary' : 'bg-white'} shadow sm:rounded border border-gray-200 p-4 cursor-pointer`} onClick={() => setShowFreeUsers(!showFreeUsers)}>
                    <h1 className='text-lg font-bold'>
                        {showFreeUsers ? 'Show Both Paid & Free Users' : 'Show Free Users'}
                    </h1>
                </div>
            </div>

            <div className="flex items-center justify-end p-4">
                <ButtonFill
                    handleClick={DownloadCSV}
                >
                    Download Conversions
                </ButtonFill>
            </div>

        </>
    )
}

export default ConversionSection;